import { tableColumnMapper } from '~/utils';
import { selects } from '~/meta/data/selects.gql';

// Tables
const columns = {
  deviceUsage: {
    __graphqlType: 'Geraeteeinsatz',
    id: { header: 'Id', accessor: 'id' },
    description: { header: 'Bezeichnung', accessor: 'geraetetyp.bezeichnung' },
    startingDay: { header: 'Aufbau', accessor: 'beginnTag', renderer: { name: 'DateFormat' } },
    endingDay: { header: 'Abbau', accessor: 'endeTag',  renderer: { name: 'DateFormat' } },
    consumption: {
      header: 'Verbrauch',
      accessor: 'geraetetyp.nennleistung',
      cell: (info:any) => {
        const capacity = info.getValue();
        const {row} = info;
        const { beginnTag: startingDay, endeTag: endingDay } = row?.original;
        const hours = startingDay && endingDay ? (new Date(endingDay)?.getTime() - new Date(startingDay)?.getTime()) / (1000 * 3600) : null;
        return hours && capacity ? Math.round((hours * capacity + Number.EPSILON) * 100) / 100 + ' kWh (Nennleistung)' : null;
      },
    },
    settle: { header: 'J/N', accessor: 'abrechnen', renderer: { name: 'DeviceUsageSettlement' } },
    controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  deviceUsage: {
    columns: ['id', 'description', 'startingDay', 'endingDay', 'consumption', 'settle', 'controls'].map(tableColumnMapper(columns.deviceUsage)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  deviceUsage: {
    __graphqlInput: 'GeraeteeinsatzInput',
    deviceTypeId: { api: { path: 'geraetetypId', required: true } },
    deviceId: { api: { path: 'geraeteId' } },
    residentialUnitId: { api: { path: 'subprojektWohneinheitId', required: true } },
    startingDay: { api: { path: 'beginnTag' } }, // AWSDate
    endingDay: { api: { path: 'endeTag' } }, // AWSDate
    calculationSnippetId: { api: { path: 'berechnungsmethodeSnippetId' }, defaultValue: 276 }, // TODO: test for hard-coded number
    settle: { api: { path: 'abrechnen', required: true } },
  },
};

export const forms = {
  deviceUsage: [
    { path: 'residentialUnitId', ui: { label: 'Wohneinheit', props: { isDisabled: true } } },
    { path: 'deviceTypeId', ui: { label: 'Gerätetyp', component: 'QuerySelect', props: { query: selects.deviceType } } },
    { path: 'startingDay', ui: { label: 'Aufbau', component: 'DatePicker' } },
    { path: 'endingDay', ui: { label: 'Abbau', component: 'DatePicker' } },
    { path: 'settle', ui: { label: 'abrechnen', component: 'Checkbox' } },
  ],
};
