import gql from 'graphql-tag';

const calculationPositionFields = gql`
  fragment CalculationPositionFields on Kalkulationsposition {
    id
    beschreibung
    einheit
    einzelpreis
    lfdNr
    menge
    rahmenvertragspositionId
    rahmenvertragsposition {
      id
      nr
      beschreibung
      rahmenvertragId
      rahmenvertrag {
        id
        bezeichnung
      }
      leistung {
        einheit
        bezeichnung
      }
    }
    subprojektId
    subprojektWohneinheitId
    subprojektWohneinheit: subprojekt_wohneinheit {
      aufmass {
        id
        bezeichnung
        laenge
        breite
        hoehe
        typSnippet {
            id
            name
        }
      }
    }
  }
`;

export const calculationPosition = {
  createMutation: gql`
    mutation createCalculationPosition($data: KalkulationspositionInput!) {
      item: createKalkulationsposition(data: $data) {
        item {
          ...CalculationPositionFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${calculationPositionFields}
  `,
  updateMutation: gql`
    mutation updateCalculationPosition($id: Int!, $data: KalkulationspositionInput!) {
      item: updateKalkulationsposition(id: $id, data: $data) {
        item {
          ...CalculationPositionFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${calculationPositionFields}
  `,
  deleteMutation: gql`
    mutation deleteCalculationPosition($id: Int!) {
      item: deleteKalkulationsposition(id: $id) {
        item {
          ...CalculationPositionFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${calculationPositionFields}
  `,
  listQuery: gql`
    query listCalculationPositions($offset: Int, $limit: Int, $orderBy: [KalkulationspositionOrderByInput], $filter: KalkulationspositionFilterInput!) {
      items: kalkulationsposition(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        pageInfo {
          pageCount
          totalCount
        }
        items {
          ...CalculationPositionFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${calculationPositionFields}
  `,
  getQuery: gql`
    query getCalculationPosition($id: Int!) {
      item: kalkulationspositionById(id: $id) {
        item {
          ...CalculationPositionFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${calculationPositionFields}
  `,
};

export const selects = {
  residentialUnits: gql`
    query selectSubprojectResidentialUnits($offset: Int, $limit: Int, $orderBy: [SubprojektWohneinheitOrderByInput], $filter: SubprojektWohneinheitFilterInput) {
      items: subprojektWohneinheit(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          id
          subprojektId
          beteiligter {
            id
            version
            subprojektId
            typSnippetId
            kontakt {
              id
              version
              anredeSnippetId
              email
              fax
              firma1
              firma2
              freitext {
                html
                raw
              }
              landSnippetId
              name
              ort
              plz
              postfach
              statusSnippetId
              strasse
              telefon
              telefonDirekt
              telefonMobil
              telefonPrivat
              typSnippetId
              typSnippet {
                id
                name
                kategorie
              }
              vorname
              zustellOrt
              zustellPlz
              zustellStrasse
            }
          }
          aufmass {
            id
            bezeichnung
            laenge
            breite
            hoehe
            typSnippet {
                id
                name
            }
          }
          wohneinheit {
            id
            nr
            istGesamtobjekt
            bezeichnung
            deleted
            projekt {
              id
              plz
              ort
              strasse
            }
          }
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
};
