import { tableColumnMapper } from "~/utils/tableColumnMapper";
import { selects } from "~/meta/data/selects.gql";
import {format, addDays} from 'date-fns';
import {join}from '~/utils/join';

// DataTable
const columns = {
  __graphqlType: 'Aufgabe',
  id: { header: 'Id', accessor: 'id' },
  createdAt: { header: 'Angelegt am', accessor: 'angelegt', renderer: { name: 'DateTimeFormat' } },
  createdBy: { header: 'Angelegt von', accessor: 'urheber', renderer: { name: 'Name' } },
  task: { header: 'Aufgabe', accessor: 'typSnippet.name' },
  type: { header: 'Typ', accessor: 'subprojekt.projekttyp.name', renderer: { name: 'ProjectType' } },
  editor: { header: 'Zu erledigen von', accessor: 'sachbearbeiter', renderer: { name: 'Name' } },
  taskFunction: { header: 'Funktion', accessor: 'funktion.bezeichnung'},
  completedOn: { header: 'Erledigt', accessor: 'erledigt', renderer: { name: 'DateAndDoneBy' } },
  description: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'Description' } },
  controls: { accessor: 'controls', enableSorting: false, renderer: { name: 'Controls' } },
};

export const tables = { 
  columns: ['id', 'task', 'editor', 'taskFunction', 'completedOn', 'description', 'createdBy', 'createdAt', 'controls'].map(tableColumnMapper(columns)),
  hiddenColumns: ['id'],
  pageSize: 25,
};

function getStartDatumProps(daysAhead = 30, props:any = {}) {
    const currentDate = new Date();
    const min = format(currentDate, `yyyy-MM-dd`);
    const max = format(addDays(currentDate, daysAhead), 'yyyy-MM-dd');
    const defaultValue = format(new Date(), 'yyyy-MM-dd');
    return { min, max, defaultValue,...props };
  }

// Forms
export const fields = {
      __graphqlInput: 'AufgabeCreateInput' || 'AufgabeUpdateInput',
      subprojectId: { api: { path: 'subprojektId', required: true } },
      taskFunctionId: { api: { path: 'funktionId' } },
      typeSnippetId: { api: { path: 'typSnippetId', required: true } },
      description: { api: { path: 'beschreibung' }, defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }] }, // AWSJSON!
      employeeId: { api: { path: 'mitarbeiterIdSachbearbeiter' } },
      createdById: { api: { path: 'mitarbeiterIdUrheber' } },
      // role: { api: { path: 'rolleId' } },
      date: { api: { path: 'zuErledigen', defaultValue: format(new Date(), `yyyy-MM-dd`) } },
      doneBy: { api: { path: 'erledigt' } },
  };
  
  export const forms :any =  [
      { path: 'subprojectId', ui: { label: 'Projekt-Nr.', props: { isDisabled: true } } },
      { path: 'object', include:false, ui: { label: 'Objekt', props: { isDisabled: true } } },
      { path: 'client', include:false, ui: { label: 'Auftraggeber', props: { isDisabled: true } } },
      {
        path: 'employeeId',
        ui: ({viewerIsCreator}:any = {}) => {
          return {
            label: 'Aufgabe für',
            component: 'QuerySelect',
            props: {
              limit: 1000,
              placeholder: 'Mitarbeiter',
              isClearable: true,
              isDisabled: !viewerIsCreator,
              query: selects.employees,
              mapOptions: (item:any) => ({
                value: item?.value,
                label: join([item?.name, item?.vorname]),
              }),
            },
          };
        },
      },
      {
        path: 'typeSnippetId',
        ui: ({viewerIsCreator}:any = {}) => ({
          label: 'Aufgabentyp',
          component: 'SnippetSelect',
          props: {
            category: 'Aufgabe',
            property: 'Typ',
            isDisabled: !viewerIsCreator,
            isClearable: true,
            mapOptions: (item:any) => {
              return {
                value: item?.value,
                label: item?.label,
                key: item?.key,
              };
            },
          },
        }),
      },
      {
        path: 'taskFunctionId',
        ui: ({viewerIsCreator}:any = {}) => ({
          label: 'Funktion', 
          component: 'QuerySelect', 
          props: { 
            query: selects.functions,
            isDisabled: !viewerIsCreator,
             placeholder: 'Funktion',
             isClearable: true, 
          },
      }),
        // decorators: {
        //   form: [
        //     (value: any) => {
        //       if (Array.isArray(value)) {
        //         if(Boolean(value.length)){
        //           return value?.[0]?.funktionId;
        //         }
        //         return undefined
        //       }
        //       return value;
        //     },
        //   ],
        // },
      },
      // { path: 'role', ui: { label: 'Rolle', component: 'QuerySelect', props: { query: selects.function, placeholder: 'Rolle', isClearable: true } } },
      { path: 'date', ui:({viewerIsCreator}:any = {}) =>({ label: 'Startdatum', component: 'DatePicker', props: getStartDatumProps(30,{isDisabled: !viewerIsCreator}) }) },
      {
        path: 'doneBy',
        ui: ({reopenTaskEnabled, task, canMarkAsDone }:any = {}) => {
          const done = task?.erledigt;
          const description = done  && task?.abschlusszustaendiger ? `${join([task?.abschlusszustaendiger?.name,task?.abschlusszustaendiger?.vorname])} (${format(new Date(done), 'dd.MM.yyyy')})`: ''
          return { 
            label: 'Erledigt', 
            props: { 
              description,
              ...( done && !reopenTaskEnabled && { isDisabled: true }), 
              ...( !done && !canMarkAsDone && { isDisabled: true})
            }, 
            component: 'CheckboxControl' };
        },
      },
      { path: 'description', ui:({viewerIsCreator}:any = {}) => ({ label: 'Bemerkung', component: 'RichTextEditor', props: { hasSnippets: false, isDisabled: !viewerIsCreator }, colSpan: 2 }) },
    ];
  