export const permissionGroups = [
    {
        id: 'project',
        name: 'Projekte',
        type: 'CRUDL',
        resources: [
            {
                id: 'project.project',
                name: 'Projekt',
                permissions: [],
            },
            {
                id: 'project.schedule',
                name: 'Terminplan',
                permissions: [],
            },
            {
                id: 'project.correspondence',
                name: 'Korrespondenz',
                permissions: [],
            },
            {
                id: 'project.tasks',
                name: 'Aufgaben',
                permissions: [],
            },
            {
                id: 'project.residentialUnits',
                name: 'Wohneinheiten',
                permissions: [],
            },
            {
                id: 'project.participants',
                name: 'Beteiligte',
                permissions: [],
            },
            {
                id: 'project.documents',
                name: 'Dokumente',
                permissions: [],
            },
            {
                id: 'project.measures',
                name: 'Aufmaß',
                permissions: [],
            },
            {
                id: 'project.report',
                name: 'Ortungsbericht',
                permissions: [],
            },
            {
                id: 'project.deviceUsage',
                name: 'Geräteeinsatz',
                permissions: [],
            },
        ],
    },
    {
        id: 'finance',
        name: 'Kalkulation',
        type: 'CRUDL',
        resources: [
            {
                id: 'finance.offer',
                name: 'Angebot',
                permissions: [],
            },
            {
                id: 'finance.invoice',
                name: 'Rechnung',
                permissions: [],
            },
            {
                id: 'finance.calculation',
                name: 'Kalkulation',
                permissions: [],
            },
        ],
    },
    {
        id: 'baseData',
        name: 'Stammdaten',
        type: 'CRUDL',
        resources: [
            {
                id: 'masterData.controlling',
                name: 'Controlling',
                permissions: [],
            },
            {
                id: 'masterData.deviceType',
                name: 'Gerätetyp',
                permissions: [],
            },
            {
                id: 'masterData.trade',
                name: 'Gewerk',
                permissions: [],
            },
            {
                id: 'masterData.contacts',
                name: 'Kontakte',
                permissions: [],
            },
            {
                id: 'masterData.branches',
                name: 'Niederlassungen',
                permissions: [],
            },
            {
                id: 'masterData.projectType',
                name: 'Projekttyp',
                permissions: [],
            },
            {
                id: 'masterData.agreements',
                name: 'Rahmenvereinbarungen',
                permissions: [],
            },
            {
                id: 'masterData.function',
                name: 'Funktion',
                permissions: [],
            },
            {
                id: 'masterData.others',
                name: 'Sonstige Stammdaten',
                permissions: [],
            },
            {
                id: 'masterData.textSnippets',
                name: 'Textbausteine',
                permissions: [],
            },
            {
                id: 'masterData.insurances',
                name: 'Versicherungen',
                permissions: [],
            },
        ],
    },
    {
        id: 'staff',
        name: 'Personal',
        type: 'CRUDL',
        resources: [
            {
                id: 'staff.permissions',
                name: 'Berechtigungen',
                permissions: [],
            },
            {
                id: 'staff.employees',
                name: 'Mitarbeiter',
                permissions: [],
            },
            {
                id: 'staff.absences',
                name: 'Urlaub',
                permissions: [],
            },
        ],
    },
    {
        id: 'branch',
        name: 'Niederlassungen',
        type: 'FLAGS',
        resources: [
            {
                id: 'branch.kerpen',
                name: 'Kerpen',
                permissions: [],
                value: 1,
            },
            {
                id: 'branch.willich',
                name: 'Willich',
                permissions: [],
                value: 2,
            },
            {
                id: 'branch.velbert',
                name: 'Velbert',
                permissions: [],
                value: 3,
            },
            {
                id: 'branch.halle',
                name: 'Halle',
                permissions: [],
                value: 5,
            },
            {
                id: 'branch.dortmund',
                name: 'Dortmund',
                permissions: [],
                value: 1967,
            },
            {
                id: 'branch.thermographie',
                name: 'Thermographie',
                permissions: [],
                value: 1968,
            },
            {
                id: 'branch.berlin',
                name: 'Berlin',
                permissions: [],
                value: 1232694,
            },
        ],
    },
    {
        id: 'additional',
        name: 'Zusätzliche Berechtigungen',
        type: 'FLAGS',
        resources: [
            //  we leave them in but disabled for later purpose
            // {
            //     id: 'additional.editAllCorrespondence',
            //     name: 'Alle Korrespondenzen bearbeiten',
            //     permissions: [],
            // },
            // {
            //     id: 'additional.editAllTasks',
            //     name: 'Alle Aufgaben bearbeiten',
            //     permissions: [],
            // },
            {
                id: 'additional.closeTask',
                name: 'Fremde Aufgaben auf erledigt setzen',
                permissions: [],
            },
            {
                id: 'additional.reopenTask',
                name: 'Aufgabe auf unabgeschlossen setzen',
                permissions: [],
            },
            {
                id: 'additional.downloadProjects',
                name: 'Projekte runterladen',
                permissions: [],
            },
            {
                id: 'additional.downloadContacts',
                name: 'Kontakte runterladen',
                permissions: [],
            },
            {
                id: 'additional.downloadInvoice',
                name: 'Rechnungs-PDF runterladen',
                permissions: [],
            },
            {
                id: 'additional.releaseInvoice',
                name: 'Rechnung freigeben',
                permissions: [],
            },
            {
                id: 'additional.releaseOffer',
                name: 'Angebot freigeben',
                permissions: [],
            },
        ],
    },
];
