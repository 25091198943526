import { resolveFormFields, tableColumnMapper } from '~/utils';

export const ui = { tables: [{ id: 'deviceType', colSpan: 3, snippets: { create: 'Neuer Gerätetyp' } }] };

// ===============================================
// TABLES
// ===============================================

const columns = {
  deviceType: {
    __graphqlType: 'Geraetetyp',
    id: { header: 'ID', accessor: 'id', width: 30 },
    type: { header: 'Typ', accessor: 'typ', disableSortBy: false },
    description: { header: 'Bezeichnung', accessor: 'bezeichnung', disableSortBy: true },
    manufacturer: { header: 'Hersteller', accessor: 'hersteller', disableSortBy: true },
    ratedCapacity: { header: 'Nennleistung in KW', accessor: 'nennleistung', disableSortBy: true },
    dataSheet: { header: 'Datenblatt', accessor: 'dokument', disableSortBy: true, renderer: { name: 'DeviceTypeDocument' } },
    controls: { header: '', accessor: 'controls', width: 40, renderer: { name: 'Controls' } },
  },
};

export const tables = {
  deviceType: {
    columns: ['id', 'type', 'description', 'manufacturer', 'ratedCapacity', 'dataSheet', 'controls'].map(tableColumnMapper(columns.deviceType)),
    hiddenColumns: ['id'],
  },
};

// ===============================================
// FORMS
// ===============================================

export const fields = {
  deviceType: {
    __graphqlInput: 'GeraetetypInput',
    type: { api: { path: 'typ', required: true } },
    description: { api: { path: 'bezeichnung', required: true } },
    manufacturer: { api: { path: 'hersteller', required: true } },
    capacity: { api: { path: 'nennleistung', required: true } },
    meter: { api: { path: 'mitZaehler', required: true } },
    document: { api: { path: 'dokumentId' } },
  },
};

export const forms = {
  deviceType: [
    { path: 'type', ui: { label: 'Typ' } },
    { path: 'description', ui: { label: 'Bezeichnung' } },
    { path: 'manufacturer', ui: { label: 'Hersteller' } },
    {
      path: 'capacity',
      ui: { label: 'Nennleistung in KW', props: { step: '0.01', type: 'number' } },
    },
    { path: 'meter', ui: { label: 'Mit Zähler', component: 'Checkbox', props: { defaultChecked: false } } },
    { path: 'document', ui: { label: 'Datenblatt', component: 'DocumentUpload', props: { showFileType: false }, colSpan: 2 } },
  ],
};

export const deviceType = {
  columns: tables.deviceType.columns,
  tableProps: { pageSize: 10, hiddenColumns: tables.deviceType.hiddenColumns },

  // needed by ~/plugins/projects/meta/utils
  base: { form: forms.deviceType, fields: fields.deviceType },

  getForm: (context = {}) => {
    return {
      columns: 2,

      // fields, defaultValues(), validationSchema
      ...resolveFormFields(forms.deviceType, fields.deviceType, context),

      snippets: {
        create: 'Neuer Gerätetyp',
        update: 'Gerätetyp bearbeiten',
        displayName: (item: any) => `${item?.typ} ${item?.bezeichnung}`,
      },
    };
  },
};
