import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import {
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    HStack,
    SimpleGrid,
    Alert,
    AlertIcon,
    GridItem,
} from '@chakra-ui/react';
import {
    useModal,
    useYupValidationResolver,
    useAbsenceOverlap,
    useViewer,
    useDebounce,
} from '~/hooks';
import { fields, forms } from '~/pages/projects/meta/data/task.schema';
import { resolveFormFields } from '~/utils/resolveFormFields';
import { Form, renderField } from '~/components/Form/Form';
import { useTaskPermissions } from './useTaskPermissions';
import { join } from '~/utils/join';
import log from '~/log';

const prepareDefaultValues = (subproject?: any) => {
    if (!subproject) {
        return {} as any;
    }
    const { projekt, lfdNr, beteiligte } = subproject || {};
    const AG = beteiligte?.find(
        (beteiligter: any) => beteiligter?.typSnippet?.name === 'Auftraggeber'
    );
    const values = {
        subprojectId: `${projekt?.lfdNr}-${lfdNr}`,
        object: join([`${projekt?.plz} ${projekt?.ort}`, projekt?.strasse]),
        client: join([
            AG?.kontakt?.firma1,
            AG?.kontakt?.firma2,
            AG?.kontakt?.name,
            AG?.kontakt?.vorname,
        ]),
    };
    return values;
};

export function resolveIsClearable(data: { [key: string]: any } = {}, dependencies?: any[string]) {
    return dependencies.reduce(
        (acc: any, property: any) =>
            !acc.hasOwnProperty(property) ? { ...acc, [property]: 0 } : acc,
        data
    );
}

export const TaskModal = (props: any) => {
    const [refreshKey, setRefreshKey] = React.useState(0);
    const { hasPermission, ...viewer } = useViewer();

    const [hasOverlap, setHasOverlap] = React.useState<boolean>(false);
    const checkAbsenceOverlapping = useAbsenceOverlap();
    const { state, dispatch, onClose } = useModal();
    const { createMutation, updateMutation, isLoading } = state?.modals?.props || props || {};

    const { row: task = {}, rowId } = state?.rows || {};
    const formFields = resolveFormFields(forms as any, fields);



    const taskId = useDebounce(rowId, 500);
    const subprojectId = props?.subprojectId || task?.subprojektId;
    const subproject = props?.subproject || task?.subprojekt;



    const defaultValues = {
        ...formFields.defaultValues,
        ...formFields.toForm(task),
        taskFunctionId: task?.funktionId ? { value: task?.funktionId } : null,
        ...prepareDefaultValues(subproject),
        ...(task?.zuErledigen ||
            (task?.angelegt && {
                date: format(new Date(task?.zuEredigen || task?.angelegt), 'yyyy-MM-dd'),
            })),
    };

    const {
        handleSubmit,
        control,
        register,
        watch,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues,
        shouldFocusError: true,
        resolver: useYupValidationResolver(
            yup.object({
                ...formFields.rules,
                taskFunctionId: yup
                    .object()
                    .nullable()
                    .test(
                        'taskFunctionId',
                        `Bitte "Funktion" oder "Aufgabe für" ausfüllen.`,
                        (value, ctx) => [ctx.parent?.employeeId, value].some(Boolean)
                    ),
                employeeId: yup
                    .object()
                    .nullable()
                    .test(
                        'employeeId',
                        `Bitte "Aufgabe für" oder "Funktion" ausfüllen.`,
                        (value, ctx) => [ctx.parent?.taskFunctionId, value].some(Boolean)
                    ),
            })
        ),
    });


    /** Permit */
    const { context } = useTaskPermissions(task)
    const { reopenTask } = context?.permissions?.additional || {}
    const { modalTitle, cancelButtonName, cursor, pointerEvents, canCreate, canUpdate } = context || {}
    const canEditAllTasks = hasPermission('additional.editAllTasks', 'enabled');
    const viewerIsCreator = context?.viewerIsCreator || canEditAllTasks;
    const canBeEdited = canCreate || canUpdate || canEditAllTasks;
    const canMarkAsDone = rowId ? typeof canUpdate === 'boolean' ? canUpdate : canUpdate?.erledigt : canCreate;

    React.useEffect(() => {
        const description = state?.formState?.comment;
        reset({ ...defaultValues, ...(description && { description }) });
    }, [state?.formState?.comment]);

    const [employeeId, taskFunctionId, date, doneBy] = watch([
        'employeeId',
        'taskFunctionId',
        'date',
        'doneBy',
    ]);

    React.useEffect(() => {
        if ([employeeId, taskFunctionId].some(Boolean)) {
            clearErrors(['taskFunctionId', 'employeeId']);
        }
    }, [employeeId, taskFunctionId]);

    React.useEffect(() => {
        const checkOverlap = async () => {
            await checkAbsenceOverlapping(
                {
                    dateFrom: date ? new Date(date) : new Date(),
                    dateUntil: date ? new Date(date) : new Date(),
                    employeeId: employeeId?.value,
                },
                setHasOverlap
            );
        };

        checkOverlap().catch(console.error);
    }, [employeeId?.value, date]);

    const onSubmit = React.useCallback(
        async (values: any) => {
            log.debug('onSubmit.values', JSON.stringify(values, null, 2));
            const fixedValues = { subprojectId };
            log.debug('onSubmit.fixedValues', JSON.stringify(fixedValues, null, 2));
            const { aufgabeFunktion, ...data } = formFields.toGql(values, {}, fixedValues) as any;
            log.debug('onSubmit.data', JSON.stringify({ data, aufgabeFunktion }, null, 2));

            const dataInput = {
                beschreibung: data?.beschreibung ? data?.beschreibung : ' ',
                ...(Boolean(values?.doneBy)
                    ? {
                        erledigt: format(new Date(), 'yyyy-MM-dd'),
                        mitarbeiterIdAbschlusszustaendiger: viewer?.app?.mitarbeiterId,
                    }
                    : { erledigt: null }),
            };
            const updateWithIsClearable = async () => {
                const clearedOutData = resolveIsClearable(data, [
                    'mitarbeiterIdAbschlusszustaendiger',
                    'mitarbeiterIdSachbearbeiter',
                    'rolleId',
                    'typSnippetId',
                    'funktionId',
                ]);
                log.debug('Tasks.onSubmit.updateWithIsClearable', taskId, {
                    ...clearedOutData,
                });

                return await updateMutation({
                    variables: {
                        id: taskId,
                        version: task?.version,
                        data: { ...clearedOutData, ...dataInput },
                        forceOverwrite: true,
                    },
                });
            };

            log.debug(taskId ? `UPDATE ${taskId}` : `CREATE`);
            const response = taskId
                ? await updateWithIsClearable()
                : await createMutation({
                    variables: {
                        data: {
                            ...data,
                            ...dataInput,
                            ...(viewer?.app?.mitarbeiterId && {
                                mitarbeiterIdUrheber: viewer?.app?.mitarbeiterId,
                            }),
                        },
                    },
                });

            log.debug('onSubmit', JSON.stringify(response, null, 2));
        },
        [subprojectId, taskId, task?.aufgabeFunktion, viewer]
    );

    const onSubmitWithOnClose = async (values: any) => {
        await onSubmit(values);
        onReset();
    };

    const onReset = () => {
        dispatch?.({ type: 'resetState' });
        onClose?.();
    };

    const onSubmitWithNext = async (values: any) => {
        await onSubmit(values);
        reset({
            ...formFields.defaultValues,
            ...prepareDefaultValues(subproject),
            ...(task?.zuErledigen ||
                (task?.angelegt && {
                    date: format(new Date(task?.zuEredigen || task?.angelegt), 'yyyy-MM-dd'),
                })),
        });
        setRefreshKey(Date.now());
    };

    return (
        <ModalContent maxWidth="container.md" rounded="none">
            <ModalHeader
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderBottomWidth={1}
                borderColor="gray.200"
                mb={6}
                p={5}
            >
                {modalTitle}
                <HStack>
                    <Button data-test-id="button-cancel" variant="outline" onClick={onReset}>
                        {cancelButtonName}
                    </Button>
                    {canBeEdited && (
                        <>
                            <Button
                                data-test-id="button-save-task-goto-next"
                                variant="outline"
                                onClick={handleSubmit(onSubmitWithNext)}
                            >
                                Speichern + Neu
                            </Button>
                            <Button
                                isLoading={isLoading}
                                data-test-id="button-save-task"
                                colorScheme="blue"
                                onClick={handleSubmit(onSubmitWithOnClose)}
                                isDisabled={hasOverlap}
                            >
                                Speichern
                            </Button>
                        </>
                    )}
                </HStack>
            </ModalHeader>
            <ModalBody cursor={cursor} >
                <Form onSubmit={handleSubmit(onSubmitWithOnClose)}>
                    <SimpleGrid spacing={4} columns={2} pointerEvents={pointerEvents}>
                        {formFields.fields.map((field: any) => (
                            <React.Fragment key={field.name + taskId + refreshKey}>
                                {renderField({
                                    field,
                                    control,
                                    register,
                                    errors,
                                    context: {
                                        canMarkAsDone,
                                        viewerIsCreator,
                                        ...(props?.branchId && { branchId: props?.branchId }),
                                        reopenTaskEnabled: reopenTask,
                                        task,
                                    },
                                })}
                            </React.Fragment>
                        ))}
                        {employeeId && hasOverlap && !Boolean(doneBy) && (
                            <React.Fragment key={employeeId?.value}>
                                <GridItem colSpan={2}>
                                    <Alert status="warning">
                                        <AlertIcon />
                                        {`Der Mitarbeiter ${employeeId?.label
                                            } ist krank oder abwesend, ihm/ihr können ${date
                                                ? `am ${format(new Date(date), 'dd.MM.yy')}`
                                                : 'z.Z.'
                                            } keine Aufgaben zugeordnet werden.`}
                                    </Alert>
                                </GridItem>
                            </React.Fragment>
                        )}
                        {!canBeEdited && (
                            <React.Fragment key={employeeId?.value}>
                                <GridItem colSpan={2}>
                                    <Alert status="warning">
                                        <AlertIcon />
                                        {/* Sie haben nur eingeschränkten Zugriff, da sie weder der
                                        Ersteller noch der zuständige Sachbearbeiter sind und/oder
                                        Ihnen die nötige Funktion der Aufgabe oder eine
                                        Überschneidung ihrer Funktionen mit denenen des zuständigen
                                        Sachbearbeiters fehlt. */}
                                        {`Sie haben nicht ausreichend Berechtigungen, um diese Aufgabe als ${task?.erledigt && !reopenTask ? 'un' : ''}erledigt zu markieren.`}
                                    </Alert>
                                </GridItem>
                            </React.Fragment>
                        )}
                    </SimpleGrid>
                </Form>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </ModalContent>
    );
};


