import React from 'react';
import { format, addMinutes } from 'date-fns';
import { get } from 'lodash';
import {
    HStack,
    VStack,
    Text,
    chakra,
    Flex,
    SimpleGrid,
    GridItem,
    useBoolean,
    Box,
    Checkbox as ChakraCheckbox,
    useDisclosure,
    useClipboard,
    Link,
    TextProps,
    Tooltip,
    Icon,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { EditRowMenu } from '@ucc/react/ui';
import {
    generateProjectNumber,
    generateProjectNumberOutOfSubprojectData,
} from '~/utils/generateProjectNumber';
import { ProjectType as ProjectTypeComponent } from '~/components/Layout/ProjectType';
import { join, getCommaDecimals } from '~/utils';
import { useAsset } from '~/hooks/useAsset';
import { useNavigate, useParams } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { BiCalendarEvent } from 'react-icons/bi';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDeviceUsageSettlement } from '~/pages/projects/hooks/useDeviceUsageSettlement';
import { useElectricityMeterSettlement } from '~/pages/projects/hooks/useElectricityMeterSettlement';
import { ShowContactPopover } from '../ShowContactPopover';
import { usePermission } from '~/hooks';
import log from '~/log';

export const ProjectTrade = (info: any) => {
    const value = info?.getValue();
    return join(value?.map?.((g: any) => g?.gewerk?.name)) || '' || null;
};

export const TaskFunction = (info: any) => {
    const { row: { original }, } = info;
    const taskFunction = original?.funktion || {};
    return (
        <Text >{taskFunction?.bezeichnung}</Text>
    );
};

export const CommaDecimals = (info: any) => {
    const value = info.getValue();
    return value ? getCommaDecimals(value) : null
};

export const HighlightItem = ({ row, value }: any) => {
    const isHighLighted = row?.original?.isStandard;

    return (
        <SimpleGrid columns={1} whiteSpace="normal">
            {isHighLighted ? (
                <Flex align="center" justify="start">
                    <CheckCircleIcon width="20px" height="20px" mr="10px" />
                    {row?.original?.bezeichnung}
                </Flex>
            ) : (
                <GridItem>{row?.original?.bezeichnung}</GridItem>
            )}
        </SimpleGrid>
    );
};

export const DeviceUsageSettlement = (info: any) => {
    const value = info.getValue();
    const {
        row: { original },
    } = info;
    const { updateSettlement, loading } = useDeviceUsageSettlement();
    const { canEdit } = usePermission('project.deviceUsage');
    const isDisabled = loading || !canEdit;

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await updateSettlement({
            id: original?.id,
            deviceUsage: original,
            settle: e.target.checked,
        });
    };

    if (!original?.id) {
        return null;
    }

    return (
        <ChakraCheckbox isChecked={value} isDisabled={isDisabled} size="lg" onChange={onChange} />
    );
};

export const ElectricityMeterSettlement = (info: any) => {
    const value = info.getValue();
    const {
        row: { original },
    } = info;
    const { updateSettlement, loading } = useElectricityMeterSettlement();
    const { canEdit } = usePermission('project.deviceUsage');
    const isDisabled = loading || !canEdit;

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await updateSettlement({
            id: original?.id,
            electricityMeter: original,
            settle: e.target.checked,
        });
    };

    if (!original?.id) {
        return null;
    }

    return (
        <ChakraCheckbox isChecked={value} isDisabled={isDisabled} size="lg" onChange={onChange} />
    );
};

export const PriceFormat = (info: any) => {
    const value = info.getValue();
    return value
        ? new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        }).format(value)
        : null;
};

export const EmployeeFunctions = (info: any) => {
    const { row: { original } } = info;
    const functions = original?.mitarbeiterFunktion || [];
    return functions.length ? (
        <VStack align="flex-start">
            {functions?.map(({ id, funktion }: any) => (
                <Text key={id}>{funktion?.bezeichnung}</Text>
            ))}
        </VStack>
    ) : null;
};

export const ShowContact = (info: any) => {
    const params = useParams()
    let contact = info.getValue();


    const [isHovered, setIsHovered] = React.useState<string>('');
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { onCopy, setValue, value: selectedEntry, hasCopied } = useClipboard('');

    if (info?.row?.original?.__typename === 'SubprojektWohneinheit') {
        contact = contact?.[0]?.kontakt;
    }

    if (Array.isArray(contact) && params?.subprojectId) {
        const found = contact?.find?.((c: any) => c?.subprojektId === parseInt(params?.subprojectId as string));
        contact = found?.beteiligter?.[0]?.kontakt;
    }

    React.useEffect(() => {
        selectedEntry && onCopy();
    }, [selectedEntry]);

    if (!contact) {
        return null;
    }

    const close = () => {
        setValue('');
        onClose();
    };

    const label = join([contact?.firma1, contact?.firma2, contact?.name, contact?.vorname]) || '';

    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <ShowContactPopover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={close}
                label={label}
                contact={contact}
                setValue={setValue}
                hasCopied={hasCopied}
                isHovered={isHovered}
                selectedEntry={selectedEntry}
                setIsHovered={setIsHovered}
            />
        </Box>
    );
};

export const BranchName = (info: any) => {
    const { row: { original } } = info;
    const value = original?.niederlassung;
    return value ? join([value?.nummer, value?.bezeichnung], ' - ') : null;
};

export const Desc = (info: any) => {
    const value = info.getValue();
    return (
        <SimpleGrid column={1} whiteSpace="normal">
            <chakra.p>{value}</chakra.p>
        </SimpleGrid>
    );
};


export const CalculationDescription = ({ row }: any) => {
    const { rahmenvertragsposition, beschreibung } = row?.original || {};
    return (
        <VStack align="start" spacing={0} overflow="hidden">
            {rahmenvertragsposition?.beschreibung && (
                <Text>{rahmenvertragsposition?.beschreibung}</Text>
            )}
            {beschreibung && (
                <Text>
                    {beschreibung?.split?.('m&sup2')?.join?.('m²').split('m&sup3').join('m³')}
                </Text>
            )}
        </VStack>
    );
};

export const ContactDetails = (info: any) => {
    const { row: { original } } = info;
    const [isHovered, setIsHovered] = React.useState<string>('');
    const { hasCopied, onCopy, value, setValue } = useClipboard('');
    const field2label = {
        email: 'Email',
        telefon: 'Tel',
        telefonDirekt: 'Tel (direkt)',
        telefonMobil: 'Tel (mobil)',
        telefonPrivat: 'Tel (privat',
    };

    React.useEffect(() => {
        value && onCopy();
    }, [value]);

    const items = Object.entries(original)
        .filter(([prop, val]) => Object.keys(field2label).includes(prop) && Boolean(val))
        .map(([prop, val]: any) => {
            return (
                <React.Fragment key={prop}>
                    <Tooltip
                        label={hasCopied ? 'kopiert' : 'kopieren'}
                        isOpen={!hasCopied ? isHovered === val && value !== val : value === val}
                        placement="top"
                        hasArrow
                    >
                        <Text
                            cursor="pointer"
                            onClick={() => setValue(val as string)}
                            onMouseEnter={() => setIsHovered(val as string)}
                            onMouseLeave={() => setIsHovered('')}
                        >
                            {`${field2label?.[prop as keyof typeof field2label]}: `}
                            <chakra.span color={isHovered === val ? 'blue.500' : 'revert'}>
                                {val}
                            </chakra.span>
                        </Text>
                    </Tooltip>
                </React.Fragment>
            );
        });
    return (
        <VStack align="start" onClick={(e) => e.stopPropagation()}>
            {items}
        </VStack>
    );
};


export const Employee = (info: any) => {
    const { row: { original }, } = info;
    const accessor = info?.column?.id
    const [entity] = accessor.split('.')
    const employee = accessor.includes?.('.') ? original?.[entity] : info.getValue();
    return employee ? join([employee?.name, employee?.vorname]) : null;
};

export const ProjectNumber = ({ row }: any) => {
    return generateProjectNumberOutOfSubprojectData(row.original);
};

export const SubprojectNumber = (info: any) => {
    const value = info.getValue();
    const { row } = info;
    console.log('SubprojectNumber', { value, row });
    return generateProjectNumber(
        row.original?.anlageDatum,
        row.original?.niederlassung?.nummer,
        value
    );
};

export const ProjectParticipants = (info: any) => {
    const auftraggeber = info
        .getValue()
        ?.find((p: any) => p.typSnippet?.name === 'Auftraggeber')?.kontakt;
    if (auftraggeber) {
        return (
            join([
                auftraggeber?.firma1,
                auftraggeber?.firma2,
                auftraggeber?.name,
                auftraggeber?.vorname,
            ]) || null
        );
    }
    return null;
};

export const Branch = (info: any) => {
    const banch = info.getValue();
    return banch ? `${banch?.nummer} - ${banch?.bezeichnung}` : null;
};

export const ProjectBranch = ({ row: { original } }: any) => {
    const { niederlassung } = original?.niederlassung ? original : original?.projekt || {};
    return niederlassung ? `${niederlassung?.nummer} - ${niederlassung?.bezeichnung}` : null;
};

export const DeviceTypeDocument = (info: any) => {
    const value = info.getValue();
    const { download } = useAsset();

    const open = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const url = await download(value?.pfad);
        if (url) {
            window.open(url);
        }
    };

    return <Link onClick={open}>{value?.name}</Link>;
};

export const CorrespondenceOrEventLabel = (info: any) => {
    const value = info.getValue();
    const {
        row: { original },
    } = info;
    const state = {
        projectId: original?.termin?.subprojekt?.projektId,
        subprojectId: original?.termin?.subprojektId,
        branchId: original?.termin?.subprojekt?.projekt?.niederlassungId,
        ...original,
    };
    log.trace('CorrespondenceOrEventLabel', original);
    const [isHovered, setIsHovered] = useBoolean();
    const navigate = useNavigate();
    return (
        <HStack
            onMouseEnter={setIsHovered.on}
            onMouseOut={setIsHovered.off}
            {...{
                ...(original?.terminId && {
                    onClick: (e) => {
                        e.stopPropagation();
                        navigate(`/projekte/termine/${original.datum}`, {
                            state,
                        });
                    },
                }),
            }}
        >
            <Text whiteSpace="nowrap">{value}</Text>
            {original?.terminId && original?.termin && (
                <Box>
                    <Icon
                        as={isHovered ? FiExternalLink : BiCalendarEvent}
                        color={
                            addMinutes(
                                new Date(original?.termin?.geplant),
                                original?.termin?.dauer
                            ) < new Date() && !original?.termin?.erledigt
                                ? 'red.500'
                                : 'blue.500'
                        }
                    />
                </Box>
            )}
        </HStack>
    );
};

export const Griddy = ({ children }: React.PropsWithChildren) => {
    return (
        <SimpleGrid column={1} whiteSpace="normal">
            <Text>{typeof children === 'string' ? children : null}</Text>
        </SimpleGrid>
    );
};

export const Content = (props: any) =>
    props?.cell && typeof props?.cell === 'function' ? (
        <Griddy>{props?.cell?.(props)}</Griddy>
    ) : null;

export const ContactName = ({ row }: any) => {
    return <Griddy>{join([row.original.name, row.original.vorname])}</Griddy>;
};

export const Name = (info: any) => {
    const value = info.getValue();
    return value ? join([value?.name, value?.vorname]) : null;
};

export const Checkbox = (info: any) => {
    return info.getValue() ? <AiOutlineCheck /> : '-';
};

export const Leckorter = (info: any) => {
    const values = info.getValue();
    return (
        <VStack align="start" spacing={0}>
            {values.map(({ id, mitarbeiter = {} }: any) => {
                return <Text key={id}>{join([mitarbeiter?.name, mitarbeiter?.vorname])}</Text>
            })}
        </VStack>
    );;
}

export const ProjectObject = ({ row: { original } }: any) => {
    const { plz, ort, strasse } = original.hasOwnProperty('plz')
        ? original
        : original?.projekt || {};
    if ([plz, ort, strasse].some(Boolean)) {
        return (
            <VStack align="start">
                <Text>{join([plz, ort], ' ')},</Text>
                <Text>{strasse}</Text>
            </VStack>
        );
    }
    return null;
};

export const ProjectTypes = (info: any) => {
    const values = info.getValue() || [];
    return (
        <HStack>
            {values.map((p: any) => (
                <ProjectTypeComponent key={join([p?.projekttyp?.id, p?.projekttyp?.name], '_')}>
                    {p.projekttyp.name || null}
                </ProjectTypeComponent>
            ))}
        </HStack>
    );
};

export const ProjectType = (info: any) =>
    info.getValue() ? <ProjectTypeComponent>{info.getValue() || null}</ProjectTypeComponent> : null;

export const ProjectNumberOutOfSubproject = ({ row, path }: any) =>
    generateProjectNumberOutOfSubprojectData(
        path ? get(row, path) : row?.original?.subprojekt || {}
    );

export const DateTimeFormat = (info: any) => {
    const value = info.getValue();
    return value ? format(new Date(value), 'dd.MM.yyyy, HH:mm') : null;
};

export const DateFormat = (info: any) => {
    const value = info.getValue();
    return value ? format(new Date(value), 'dd.MM.yyyy') : null;
};

export const DateAndDoneBy = (info: any) => {
    const value = info.getValue();
    return value ? (
        <VStack align="start" spacing={0}>
            <Text>{format(new Date(value), 'dd.MM.yyyy')}</Text>
            <Text fontSize="xs" >{info.row.original?.abschlusszustaendiger?.kennung}</Text>
        </VStack>
    ) : null;
};

export const ProjectDetails = ({ row }: any) => {
    const { original } = row;
    const projectNumber = generateProjectNumberOutOfSubprojectData(original?.subprojekt || {});

    const client = original?.subprojekt?.beteiligte?.find?.(
        (bet: any) => bet?.typSnippet?.name === 'Auftraggeber'
    )?.kontakt;

    const props: TextProps = {
        w: '90px',
        whiteSpace: 'nowrap',
    };

    return (
        <SimpleGrid columns={1} minW="250px" userSelect="none">
            <HStack align="start">
                <Text {...props}>Projekt-Nr:</Text>
                <Text whiteSpace={props.whiteSpace}>{projectNumber}</Text>
            </HStack>
            <HStack align="start">
                <Text {...props}>Typ:</Text>
                <ProjectTypeComponent>
                    {original?.subprojekt?.projekttyp?.name}
                </ProjectTypeComponent>
            </HStack>
            {client && (
                <HStack align="start">
                    <Text {...props}>Aufraggeber:</Text>
                    <Text>
                        {join([client?.firma1, client?.firma2, client?.name, client?.vorname])}
                    </Text>
                </HStack>
            )}
            <HStack align="start">
                <Text {...props}>Objekt:</Text>
                <VStack align="start" spacing={0}>
                    <Text>
                        {join(
                            [
                                original?.subprojekt?.projekt?.plz,
                                original?.subprojekt?.projekt?.ort,
                            ],
                            ' '
                        )}
                    </Text>
                    <Text>{original?.subprojekt?.projekt?.strasse}</Text>
                </VStack>
            </HStack>
        </SimpleGrid>
    );
};

type Control = 'divider' | { title: string; props: any; enabled?: (row: any) => boolean };
export const Controls = ({ row, controls = [] }: any) => {

    if (!controls.length) {
        return null;
    }

    const menu = controls.map((control: Control, idx: number) => {
        if (control === 'divider') {
            return <EditRowMenu.Divider key={idx} />;
        }

        if (control?.title === 'divider') {
            if (typeof control?.enabled === 'function' && !control?.enabled(row)) {
                return null;
            }
            return <EditRowMenu.Divider key={idx} />;
        }

        if (!control?.title) {
            return null;
        }

        if (typeof control?.enabled === 'function' && !control?.enabled(row)) {
            return null;
        }

        const { onClick = () => null, ...props } =
            typeof control.props === 'function'
                ? control.props(row)
                : control.props || {};


        return (
            <EditRowMenu.Item
                key={idx}
                {...props}
                onClick={() => onClick(row)}
                data-test-id={join(
                    [control.title?.toLowerCase?.(), row.original.id || row?.id],
                    '-'
                )}
            >
                {control.title}
            </EditRowMenu.Item>
        );
    })

    const emptyMenu = menu?.reduce((acc: any, item: any, idx: number) => {
        const control = controls?.[idx]

        if (!item) {
            return acc
        }

        if (acc) {
            return acc
        }

        return (control === 'divider' || control?.title === 'divider') ? false : true
    }, false)


    if (!emptyMenu) {
        return null
    }

    return (
        <HStack justify="flex-end">
            <EditRowMenu data-test-id={`table-row-controls-${row?.original?.id || row?.id}`}>
                {menu}
            </EditRowMenu>
        </HStack>
    );
};

export const OpenDocumentSelect = ({ row }: any) => {
    const { selection, ...original } = row?.original;
    const [selected, setSelected] = selection;
    const isChecked = selected.includes(original.id);

    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) =>
            e.target.checked
                ? setSelected((prev: any) => [...prev, original?.id])
                : setSelected(selected.filter((id: string) => id !== original?.id)),
        [selected]
    );

    return (
        <ChakraCheckbox
            data-test-id={`document-select-${row?.original?.id}`}
            isChecked={isChecked}
            onChange={onChange}
        />
    );
};
