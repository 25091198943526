import gql from 'graphql-tag';

const fields = gql`
  fragment DocumentFields on Dokument {
    id
    beschreibung
    deleted
    geraetetyp {
      id
      bezeichnung
      hersteller
    }
    korrespondenz {
      id
      angelegt
      mitarbeiterIdSachbearbeiter
      mitarbeiterIdUrheber
      freitext {
        html
        raw
      }
    }
    name
    pfad
    subprojektId
    typSnippetId
    angebotId
    rechnungId
    einsatzberichtId
    einsatzbericht {
      id
      einsatzberichtDetail {
        jaNein
        text
        id
        einsatzberichtOption {
          id
          bezeichnung
        }
      }
    }
    typSnippet {
      id
      name
      kategorie
      kuerzel
      eigenschaft
    }
  }
`;

export const documents = {
  createMutation: gql`
    mutation createDocument($data: DokumentInput!) {
      item: createDokument(data: $data) {
        item {
          ...DocumentFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${fields}
  `,
  updateMutation: gql`
    mutation updateDocument($id: Int!, $data: DokumentInput!) {
      item: updateDokument(id: $id, data: $data) {
        item {
          ...DocumentFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${fields}
  `,
  deleteMutation: gql`
    mutation deleteDocument($id: Int!) {
      item: deleteDokument(id: $id) {
        item {
          ...DocumentFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${fields}
  `,
  listQuery: gql`
    query listDocuments($offset: Int, $limit: Int, $orderBy: [DokumentOrderByInput], $filter: DokumentFilterInput!) {
      items: dokument(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        pageInfo {
          pageCount
          totalCount
        }
        items {
          ...DocumentFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${fields}
  `,
  getQuery: gql`
    query getDocument($id: Int!) {
      item: dokumentById(id: $id) {
        item {
          ...DocumentFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${fields}
  `,
  documentDetails: gql`
    query documentDetails($subprojektId: Int!) {
      item: subprojektById(id: $subprojektId) {
        item {
          id
          weg
          einsatzbericht {
            id
            datum
            deleted
            einsatzberichtTyp {
              id
              name
            }
            kontaktId
            kontakt {
              id
              name
              vorname
              firma1
              firma2
            }
            subprojektId
            anfahrtFrom
            anfahrtUntil
            einsatzFrom
            einsatzUntil
            abfahrtFrom
            abfahrtUntil
            einsatzberichtSachbearbeiter {
              id
              einsatzberichtId
              mitarbeiterId
              mitarbeiter {
                id
                name
                vorname
              }
            }
            mitarbeiterIdUrheber
            urheber {
              id
              name
              vorname
            }
            einsatzberichtDetail {
              id
              einsatzberichtOptionId
              einsatzberichtOption {
                id
                bezeichnung
                typ
              }
              jaNein
              anzahl
              text
            }
          }
          subprojektWohneinheit {
            id
            aufmass {
              id
              bezeichnung
              laenge
              breite
              hoehe
              typSnippet {
                name
              }
            }
            wohneinheit {
              id
              nr
              bezeichnung
              istGesamtobjekt
            }
          }
          ihrZeichen
          projekttyp {
            id
            name
          }
          projektId
          projekt {
            id
            lfdNr
            anlageDatum
            niederlassung {
              nummer
              bezeichnung
            }
            ort
            plz
            strasse
          }
          lfdNr
          bemerkung {
            html
            raw
          }
          angebote {
            id
            deleted
            nummer
            datum
            dokument {
              id
              pfad
              name
            }
            freitext {
              html
              raw
            }
            freitextFooter {
              html
              raw
            }
            gesamtpreisNetto
            mwstSatz
            sachbearbeiter {
              name
              vorname
            }
            angebotspositionen {
              id
              subprojektWohneinheitId
              beschreibung
              einheit
              einzelpreis
              menge
              lfdNr
            }
            mitarbeiterIdSachbearbeiter
            mitarbeiterIdProjektleitung
            rabatt
            statusSnippetId
            statusSnippet {
              name
            }
          }
          rechnungen {
            id
            deleted
            nummer
            datum
            dokument {
              id
              pfad
              name
            }
            freitext {
              html
              raw
            }
            freitextFooter {
              html
              raw
            }
            mwstSatz
            sachbearbeiter {
              name
              vorname
            }
            rechnungspositionen {
              id
              subprojektWohneinheitId
              beschreibung
              einheit
              einzelpreis
              menge
              lfdNr
            }
            mitarbeiterIdSachbearbeiter
            mitarbeiterIdProjektleitung
            rabatt
            statusSnippetId
            statusSnippet {
              name
            }
          }
          beteiligte {
            id
            typSnippet {
              id
              name
              kuerzel
            }
            subprojektWohneinheitId
            subprojektWohneinheit {
              id
              wohneinheitId
              wohneinheit {
                bezeichnung
              }
            }
            kontakt {
              id
              anredeSnippet {
                name
                kuerzel
              }
              freitext{
                raw
              }
              firma1
              firma2
              name
              vorname
              ort
              plz
              strasse
              telefon
              telefonDirekt
              telefonMobil
              telefonPrivat
              email
              fax
            }
          }
          termine {
            angelegt
            bemerkung
            typSnippet {
              name
              kuerzel
            }
            mitarbeiterIdUrheber
            sachbearbeiter {
              id
              name
              vorname
            }
            urheber {
              name
              vorname
              id
            }
            mitarbeiterIdSachbearbeiter
            typSnippetId
            geplant
            dauer
            subprojekt {
              id
            }
          }
          dokumente {
            beschreibung
            name
            pfad
            typSnippet {
              name
            }
          }
          scheinNr
          schadenNr
          versicherung {
            bezeichnung
          }
          aufgaben {
            angelegt
            beschreibung {
              html
              raw
            }
            zuErledigen
            mitarbeiterIdUrheber
            urheber {
              name
              vorname
            }
            typSnippetId
            typSnippet {
              name
            }
            sachbearbeiter {
              name
              vorname
            }
          }
          korrespondenzen {
            typSnippetId
            typSnippet {
              name
            }
            angelegt
            mitarbeiterIdSachbearbeiter
            mitarbeiterIdUrheber
            sachbearbeiter {
              name
              vorname
            }
            urheber {
              name
              vorname
            }
          }
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
};
