import { tableColumnMapper } from "~/utils/tableColumnMapper";
import {fields, forms } from '~/pages/projects/meta/data/task.schema';

// DataTable
const columns = {
    __graphqlType: 'Aufgabe',
    id: { header: 'Id', accessor: 'id' },
    createdAt: { header: 'Angelegt am', accessor: 'angelegt', renderer: { name: 'DateTimeFormat' } },
    project: { header: 'Projekt', accessor: 'subprojekt.projekt.lfdNr', width: '300px', renderer: { name: 'ProjectDetails' } },
    task: { header: 'Aufgabe', accessor: 'typSnippet.name' },
    editor: { header: 'Zu erledigen von', accessor: 'sachbearbeiter.name', renderer: {name: 'Employee'} },
    function: { header: 'Funktion', accessor: 'funktion.bezeichnung' }, 
    // role: { header: 'Rolle', accessor: 'rolle.bezeichnung' },
    description: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'Description' } },
    controls: { accessor: 'controls', width: 60,  enableSorting: false, renderer: { name: 'Controls' } },
};

export const tables = {
    columns: ['id', 'createdAt', 'project', 'task', 'editor', 'function', 'role', 'description', 'controls'].map(tableColumnMapper(columns)),
    hiddenColumns: ['id'],
    pageSize: 10,
};


// Forms
export {fields, forms};

  