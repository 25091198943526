import { tableColumnMapper } from "~/utils/tableColumnMapper";

// DataTable
export const columns = {
    __graphqlType: 'AufgabeCollection',
    id: { header: 'Id', accessor: 'id' },
    subproject: { header: 'Subprojekt', accessor: 'subprojekt' },
    createdAt: { header: 'Angelegt am', accessor: 'angelegt',  whiteSpace: 'nowrap', renderer: { name: 'DateTimeFormat' }, },
    project: { header: 'Projekt', accessor: 'subprojekt.projekt.lfdNr',whiteSpace: 'nowrap', renderer: { name: 'ProjectNumberOutOfSubproject' }, },
    type: { header: 'Typ', accessor: 'subprojekt.projekttyp.name', enableHiding: true, renderer: { name: 'ProjectType' }, },
    task: { header: 'Aufgabe', accessor: 'typSnippet.name' },
    editor: { header: 'Zu erledigen von', accessor: 'sachbearbeiter', renderer: {name: 'Employee'} },
    functions: { header: 'Funktion', accessor: 'funktion.bezeichnung' },
    role: { header: 'Rolle', accessor: 'rolle.bezeichnung' },
    description: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'Description' } },
    controls: { accessor: 'controls', width: 60, enableSorting: false, renderer: { name: 'Controls' } },
};

export const tables = { 
    columns: [ 'id', 'createdAt', 'project', 'type', 'task', 'editor', 'functions', 'role', 'description', 'controls', ].map(tableColumnMapper(columns)), 
    hiddenColumns: ['id'], 
};

  