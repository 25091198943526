import { selects } from '~/meta/data/selects.gql';
import { join, tableColumnMapper, isValidDateString } from '~/utils';
import { format } from 'date-fns';

// Tables
const columns = {
  __graphqlType: 'Korrespondenz',
  id: { header: 'Id', accessor: 'id' },
  correspondence: { header: 'Korrespondenz', accessor: 'typ', renderer: { name: 'CorrespondenceOrEventLabel' } },
  type: { header: 'Typ', accessor: 'subprojekt.projekttyp.name', renderer: { name: 'ProjectType' } },
  createdAt: { header: 'Datum', accessor: 'datum', cell: (info:any) => {
    const { row: { original }} = info;
    const date = original?.terminId ? original?.termin?.geplant : original?.korrespondenz?.zeit;
    // fixes sentry RangeError "Invalid time value"
    return isValidDateString(date) ? format(new Date(date), 'dd.MM.yyyy, HH:mm') : null
  }},
  description: { header: 'Beschreibung', accessor: 'beschreibung', renderer: { name: 'Description' } },
  subprojectNo: {
    header: 'SubProjekt-Nr', 
    cell: ({row:{original}}:any) => { 
    const entity = original?.[original?.terminId ? 'termin': 'korrespondenz'];
    const projectLfdNr = entity?.subprojekt?.projekt?.lfdNr;
    const subprojectLfdNr = entity?.subprojekt?.lfdNr
    return join([projectLfdNr, subprojectLfdNr],'-')
   },
  },
  editor: { header: 'Mitarbeiter', accessor: 'sachbearbeiter', renderer: { name: 'Name' } },
  createdBy: { header: 'Angelegt von', accessor: 'urheber', renderer: { name: 'Name' } },
  controls: { accessor: 'controls', width: 60, renderer: { name: 'Controls' } },
};


export const tables = {
  correspondence: {
    columns: ['id', 'correspondence', 'createdAt', 'description', 'editor', 'createdBy', 'controls'].map(tableColumnMapper(columns)),
    hiddenColumns: ['id'],
  },
  projectOverviewCorrespondence: {
    columns: ['id', 'correspondence', 'subprojectNo', 'createdAt', 'description', 'editor', 'createdBy', 'controls'].map(tableColumnMapper(columns)),
    hiddenColumns: ['id'],
  },
  projectCorrespondence: {
    columns: ['id', 'correspondence', 'createdAt', 'description', 'editor', 'createdBy', 'controls'].map(tableColumnMapper(columns)),
    hiddenColumns: ['id'],
  },
};

// Forms
export const fields = {
  correspondence: {
    __graphqlInput: 'KorrespondenzCreateInput' || 'KorrespondenzUpdateInput',
    subprojectId: { api: { path: 'subprojektId', required: true } },
    typeSnippetId: { api: { path: 'typSnippetId', required: true }, required: true },
    time: { api: { path: 'zeit' }, defaultValue: new Date()},
    employeeId: { api: { path: 'mitarbeiterIdSachbearbeiter' } },
    participant: {
      api: {
        path: 'beteiligter',
        //TODO: doesn't need to be required
        // required: true,
        type: 'String!',
      },
      defaultValue: '',
    },
    document: { api: { path: 'dokumentId' } },
    documentType: { api: { path: 'dokumentType' } }, // TODO: DocumentType should be part of Upload component
    createdById: { api: { path: 'mitarbeiterIdUrheber' } },
    comment: { api: { path: 'freitext', required: true}, defaultValue: [{ type: 'paragraph', children: [{ text: '' }] }]  },  // AWSJSON!
  },
};

const isDisabled = (ctx: any) => ({...(!ctx?.canEditCorrespondence && {isDisabled: true})})

export const forms = {
  correspondence: [
    { path: 'subprojectId', ui: { label: 'Subprojekt Id' }, visibility: { hide: () => true }, validation: false },
    { path: 'typeSnippetId', ui:(ctx:any) => ({ label: 'Typ', component: 'SnippetSelect', props: { ...isDisabled(ctx), category: 'Korrespondenz', property: 'Typ' } }) },
    {
      path: 'time',
      ui: (ctx:any) => ({ label: 'Korrespondenzdatum', props:{...isDisabled(ctx), component: 'DateTimeLocal'}}),
      decorators: {
        api: [(value: string) => isValidDateString(value) ? new Date(value).toISOString(): ''],
        form: [(value: string) => isValidDateString(value) ? new Date(value):''],
      },
    },
    {
      path: 'employeeId',
      ui: (ctx:any) =>  ({
        label: 'Mitarbeiter',
        component: 'QuerySelect',
        props: {
          limit: 1000,
          query: selects.employees,
          formerLabel: ctx?.formerLabel,
          isDisabled: true,
          mapOptions: (item:any) =>(
                {
                  value: item?.value,
                  label: join([item?.name, item?.vorname]),
                }),
        },
      }),
      required: true,
    },
    { path: 'participant',  ui: (ctx:any) =>  ({ label: 'Beteiligter', props: isDisabled(ctx) }) },
    { path: 'document', ui: ({ subprojectId, ...ctx } = {} as any) => ({ label: 'Anhang', component: 'DocumentUpload', props: { subprojectId, ...isDisabled(ctx) } }) },
    { path: 'comment', ui:(ctx:any) => ({ label: 'Bemerkung', component: 'RichTextEditor', props: { hasSnippets: false, ...isDisabled(ctx), errors: ctx?.errors }, colSpan: 2 }) },
  ],
};
