import React from 'react';
import { VStack } from '@chakra-ui/react';
import { ProjectIds } from '~/pages/projects/types';
import { useMeasure } from '~/pages/projects/hooks/useMeasure';
import { DeviceUsage, DeviceUsageModal } from './DeviceUsage';
import { ElectricityMeter, ElectricityMeterModal } from './ElectricityMeter';
import { DeviceUsageDownload } from './DeviceUsageDownload';
import { combineReducers, modalReducer, rowReducer } from '~/reducers';
import { useModal } from '~/hooks';
import { OverrideActions } from '~/components/Layout/PageHeader';
import { MultiModal } from '~/components/MultiModal';
import { HasPermission } from '~/layout/HasPermission';
import { useProjectParams } from '../../hooks/useProjectParams';

export const Modals = {
    DeviceUsage: 'DeviceUsage',
    ElectricityMeter: 'ElectricityMeter',
};

export const DeviceUsageAndElectricalMeters = (props?: ProjectIds) => {
    const { projectId, subprojectId }: any = useProjectParams();
    const { residentialUnits } = useMeasure({ projectId, subprojectId });

    useModal({
        defaultState: { modals: { activeModal: Modals.DeviceUsage } },
        component: (
            <MultiModal>
                <DeviceUsageModal id={Modals.DeviceUsage} />
                <ElectricityMeterModal id={Modals.ElectricityMeter} />
            </MultiModal>
        ),
        reducer: combineReducers({
            modals: modalReducer(Modals.DeviceUsage),
            row: rowReducer,
        }),
    });

    return (
        <>
            <OverrideActions>
                <HasPermission resource='project.deviceUsage' permission='view'>
                    <DeviceUsageDownload {...{ projectId, subprojectId }} />
                </HasPermission>
            </OverrideActions>
            <VStack spacing={6} p={6}>
                {residentialUnits.map((unit, idx) => (
                    <React.Fragment key={unit.id}>
                        <DeviceUsage
                            dataTestId={`device-usage-${idx}`}
                            projectId={projectId}
                            subprojectId={subprojectId}
                            residentialUnit={unit}
                        />
                        <ElectricityMeter
                            dataTestId={`electricity-meter-${idx}`}
                            projectId={projectId}
                            subprojectId={subprojectId}
                            residentialUnit={unit}
                        />
                    </React.Fragment>
                ))}
            </VStack>
        </>
    );
};
