import gql from 'graphql-tag';

const subprojectSearchtFields = gql`
  fragment SubprojektSucheFields on Subprojekt {
    id
    lfdNr
    auftragsNr
    anlageDatum
    bemerkungAufmass
    abrechnungsDatum
    bonitaetSnippetId
    bonitaetSnippet {
      id
      name
      kuerzel
    }
    statusSnippetId
    statusSnippet {
      id
      name
      kuerzel
    }
    projekttypId
    projekttyp {
      id
      name
    }
    projektId
    projekt {
      id
      lfdNr
      anlageDatum
      plz
      ort
      strasse
      niederlassungId
      niederlassung {
        id
        nummer
        bezeichnung
      }
      schadenTag
      anlageDatum
      statusSnippetId
      statusSnippet {
        id
        name
      }
    }
    beteiligte {
      id
      version
      kontaktId
      kontakt {
        id
        version
        name
        vorname
        firma1
        firma2
        plz
        ort
        strasse
        telefon
        telefonDirekt
        telefonMobil
        telefonPrivat
        email
        fax
      }
      typSnippetId
      typSnippet {
        name
        kategorie
      }
    }
  }
`;

const subprojectFields = gql`
  fragment SubprojektFields on Subprojekt {
    id
    version
    deleted
    lfdNr
    bemerkung {
      html
      raw
    }
    weg
    auftragsNr
    anlageDatum
    bemerkungAufmass
    abrechnungsDatum
    bonitaetSnippetId
    bonitaetSnippet {
      id
      name
      kuerzel
    }
    buergschaft
    ihrZeichen
    notdienst
    schadenNr
    scheinNr
    versicherungId
    versicherung {
      id
      bezeichnung
    }
    versicherungsArtSnippetId
    versicherungsArtSnippet {
     id
     name
    }
    umsatzPrognose
    termine {
      id
      version
      angelegt
      geplant
      erledigt
      dauer
      mitarbeiterIdSachbearbeiter
      mitarbeiterIdUrheber
      prio
      typSnippetId
      typSnippet {
        id
        name
        kuerzel
      }
    }
    subprojektWohneinheit {
      id
      wohneinheitId
      beteiligter {
        id
        version
        kontakt {
          id
          version
          firma1
          firma2
          name
          vorname
        }
        typSnippetId
        typSnippet {
          id
          name
        }
      }
      wohneinheit {
        id
        nr
        bezeichnung
        istGesamtobjekt
      }
    }
    statusSnippetId
    statusSnippet {
      id
      name
      kuerzel
    }
    subprojektGewerk {
      id
      gewerkId
      gewerk {
        id
        name
      }
    }
    rahmenvertragId
    rahmenvertrag {
      id
      isStandard
      bezeichnung
    }
    projekttypId
    projekttyp {
      id
      name
    }
    projektId
    projekt {
      id
      version
      lfdNr
      anlageDatum
      plz
      ort
      strasse
      niederlassungId
      niederlassung {
        id
        nummer
        bezeichnung
      }
      schadenTag
      anlageDatum
      statusSnippetId
      statusSnippet {
        id
        name
      }
    }
    beteiligte {
      id
      version
      kontaktId
      kontakt {
        id
        version
        name
        vorname
        freitext {
          html
          raw
        }
        firma1
        firma2
        plz
        ort
        strasse
        telefon
        telefonDirekt
        telefonMobil
        telefonPrivat
        email
        fax
      }
      typSnippetId
      typSnippet {
        name
        kategorie
      }
    }
  }
`;

const projectFields = gql`
  fragment ProjektFields on Projekt {
    id
    version
    deleted
    lfdNr
    anlageDatum
    schadenTag
    niederlassungId
    niederlassung {
      id
      bezeichnung
      nummer
    }
    plz
    ort
    strasse
    statusSnippetId
    statusSnippet {
      id
      name
    }
    subprojekt {
      subprojektWohneinheit {
        id
        wohneinheitId
        beteiligter {
          id
          version
          kontakt {
            id
            version
            firma1
            firma2
            name
            vorname
          }
          typSnippetId
          typSnippet {
            id
            name
          }
        }
        wohneinheit {
          id
          nr
          bezeichnung
        }
      }
      id
      version
      anlageDatum
      projektId
      projekt {
        id
        lfdNr
        anlageDatum
        niederlassung {
          id
          nummer
        }
      }
      versicherungsArtSnippetId
      beteiligte {
        id
        version
        kontaktId
        kontakt {
          id
          version
          name
          vorname
          firma1
          firma2
        }
        typSnippetId
        typSnippet {
          id
          name
        }
      }
      lfdNr
      projekttypId
      bemerkung {
        html
        raw
      }
      intern
      weg
      ihrZeichen
      buergschaft
      schadenNr
      scheinNr
      notdienst
      rahmenvertragId
      versicherungId
      bonitaetSnippetId
      versicherungsArtSnippetId
      subprojektGewerk {
        id
        gewerk {
          id
          name
        }
      }
      projekttyp {
        id
        name
      }
    }
    wohneinheit {
      id
      nr
      bezeichnung
      istGesamtobjekt
    }
  }
`;

export const subproject = {
  app: {
    createSubprojectToProject: gql`
      mutation createSubprojektToProjekt($input: CreateSubprojektToProjektInput!) {
        app {
          subproject: createSubprojektToProjekt(input: $input) {
            item {
              ...SubprojektFields
            }
            error {
              code
              data
              message
            }
          }
        }
      }
      ${subprojectFields}
    `,
    updateSubprojectWithProject: gql`
      mutation updateSubprojectWithProject($input: UpdateSubprojektWithProjektInput!) {
        app {
          subproject: updateSubprojektWithProjekt(input: $input) {
            item {
              ...SubprojektFields
            }
            error {
              code
              data
              message
            }
          }
        }
      }
      ${subprojectFields}
    `,
    subprojectCsvExport: gql`
      query subprojectCsvExport($searchInput: SubprojektSearch! $orderBy:[SubprojektOrderByInput]) {
        app {
          subprojectCsvExport(searchInput: $searchInput, orderBy: $orderBy) {
            item
            error {
              code
              data
              message
            }
          }
        }
      }
    `,
    subprojectSearch: gql`
      query subprojectSearch($limit: Int, $offset: Int, $orderBy: [SubprojektOrderByInput], $searchInput: SubprojektSearch!) {
        app {
          subprojektSearch(limit: $limit, offset: $offset, orderBy: $orderBy, searchInput: $searchInput) {
            pageInfo {
              pageCount
              totalCount
            }
            items {
              ...SubprojektSucheFields
            }
            error {
              code
              data
              message
            }
          }
        }
      }
      ${subprojectSearchtFields}
    `,
  },
  createMutation: gql`
    mutation createSubproject($data: SubprojektInput!) {
      item: createSubprojekt(data: $data) {
        item {
          ...SubprojektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${subprojectFields}
  `,
  updateMutation: gql`
    mutation updateSubproject($id: Int!, $version: Int!, $forceOverwrite: Boolean, $data: SubprojektInput!) {
      item: updateSubprojekt(id: $id, version: $version, forceOverwrite: $forceOverwrite, data: $data) {
        item {
          ...SubprojektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${subprojectFields}
  `,
  deleteMutation: gql`
    mutation deleteSubproject($id: Int!, $version: Int!, $forceOverwrite: Boolean) {
      item: deleteSubprojekt(id: $id, version: $version, forceOverwrite: $forceOverwrite) {
        item {
          ...SubprojektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${subprojectFields}
  `,
  listQuery: gql`
    query listSubprojects($offset: Int, $limit: Int, $orderBy: [SubprojektOrderByInput], $filter: SubprojektFilterInput!) {
      items: subprojekt(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        pageInfo {
          pageCount
          totalCount
        }
        items {
          ...SubprojektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${subprojectFields}
  `,
  getQuery: gql`
    query getSubproject($id: Int!) {
      item: subprojektById(id: $id) {
        item {
          ...SubprojektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${subprojectFields}
  `,
};

export const project = {
  createMutation: gql`
    mutation createProject($data: ProjektInput!) {
      item: createProjekt(data: $data) {
        item {
          ...ProjektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${projectFields}
  `,
  updatedMutation: gql`
    mutation updateProject($id: Int!, $version: Int!, $forceOverwrite: Boolean, $data: ProjektInput!) {
      item: updateProjekt(id: $id, version: $version, forceOverwrite: $forceOverwrite, data: $data) {
        item {
          ...ProjektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${projectFields}
  `,
  createSubprojektWithProject: gql`
    mutation createSubprojektWithProject($input: CreateSubprojektWithProjektInput!) {
      app {
        subproject: createSubprojektWithProjekt(input: $input) {
          item {
            ...SubprojektFields
          }
          error {
            code
            data
            message
          }
        }
      }
    }
    ${subprojectFields}
  `,
  deleteProject: gql`
    mutation deleteProject($id: Int!, $version: Int!, $forceOverwrite: Boolean) {
      item: deleteProjekt(id: $id, version: $version, forceOverwrite: $forceOverwrite) {
        item {
          ...ProjektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${projectFields}
  `,
  listQuery: gql`
    query listProjects($offset: Int, $limit: Int, $orderBy: [ProjektOrderByInput], $filter: ProjektFilterInput) {
      items: projekt(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        pageInfo {
          pageCount
          totalCount
        }
        items {
          ...ProjektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${projectFields}
  `,
  getQuery: gql`
    query getProject($id: Int!) {
      item: projektById(id: $id) {
        item {
          ...ProjektFields
        }
        error {
          code
          data
          message
        }
      }
    }
    ${projectFields}
  `,
  projectDetails: gql`
    query projectDetails($projektId: Int!, $subprojektId: Int!) {
      project: projektById(id: $projektId) {
        item {
          id
          version
          lfdNr
          anlageDatum
          strasse
          plz
          ort
          niederlassungId
          niederlassung {
            id
            nummer
            bezeichnung
          }
          schadenTag
          anlageDatum
          statusSnippetId
          statusSnippet {
            id
            name
          }
        }
        error {
          code
          data
          message
        }
      }
      subproject: subprojektById(id: $subprojektId) {
        item {
          id
          version
          lfdNr
          bemerkung {
            html
            raw
          }
          projekttypId
          projekttyp {
            id
            name
          }
          notdienst
          subprojektGewerk {
            gewerkId
            gewerk {
              id
              name
            }
          }
          intern
          weg
          rahmenvertragId
          rahmenvertrag {
            id
            bezeichnung
          }
          ihrZeichen
          bonitaetSnippetId
          bonitaetSnippet {
            id
            name
          }
          buergschaft
          versicherungId
          versicherung {
            id
            bezeichnung
          }
          versicherungsArtSnippetId
          versicherungsArtSnippet {
            id
            name
          }
          subprojektWohneinheit {
            id
            wohneinheitId
            beteiligter {
              id
              version
              kontakt {
                id
                version
                firma1
                firma2
                name
                vorname
              }
              typSnippetId
              typSnippet {
                id
                name
              }
            }
            wohneinheit {
              id
              nr
              bezeichnung
            }
          } 
          schadenNr
          scheinNr
          beteiligte {
            id
            version
            kontaktId
            kontakt {
              id
              version
              name
              vorname
              firma1
              firma2
              plz
              ort
              strasse
              telefon
              telefonDirekt
              telefonMobil
              telefonPrivat
              email
              fax
            }
            typSnippetId
            typSnippet {
              name
              kategorie
            }
          }
          umsatzPrognose
          abrechnungsDatum
          auftragsNr
          statusSnippet {
            id
            name
          }
          bemerkungAufmass
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
};

export const selects = {
  projectType: gql`
    query selectProjectType($offset: Int, $limit: Int, $orderBy: [ProjekttypOrderByInput], $filter: ProjekttypFilterInput) {
      items: projekttyp(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          value: id
          label: name
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
  branches: gql`
    query selectBranches($offset: Int, $limit: Int, $orderBy: [NiederlassungOrderByInput], $filter: NiederlassungFilterInput) {
      items: niederlassung(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          value: id
          label: bezeichnung
          nummer
          bezeichnung
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
  trade: gql`
    query selectTrade($offset: Int, $limit: Int, $orderBy: [GewerkOrderByInput], $filter: GewerkFilterInput) {
      items: gewerk(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          value: id
          label: name
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
  generalAgreements: gql`
    query selectGeneralAgreements($offset: Int, $limit: Int, $orderBy: [RahmenvertragOrderByInput], $filter: RahmenvertragFilterInput) {
      items: rahmenvertrag(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          value: id
          label: bezeichnung
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
  insurances: gql`
    query selectInsurances($offset: Int, $limit: Int, $orderBy: [VersicherungOrderByInput], $filter: VersicherungFilterInput) {
      items: versicherung(offset: $offset, limit: $limit, orderBy: $orderBy, filter: $filter) {
        items {
          value: id
          label: bezeichnung
        }
        error {
          code
          data
          message
        }
      }
    }
  `,
};
