import { useSecurity } from '@ucc/react/security';
import { Viewer } from '~/gql/ucc/validation';
import { GetEmployeeDocument } from '~/gql/ucpw/graphql';
import { useQuery } from '@apollo/client';

export type Permission = 'view' | 'create' | 'update' | 'delete' | 'enabled';
export type Resource =
    | 'project.project'
    | 'project.residentialUnits'
    | 'project.participants'
    | 'project.tasks'
    | 'project.correspondence'
    | 'project.report'
    | 'project.schedule'
    | 'project.deviceUsage'
    | 'project.measures'
    | 'project.documents'
    | 'finance.invoice'
    | 'finance.offer'
    | 'finance.calculation'
    | 'masterData.controlling'
    | 'masterData.deviceType'
    | 'masterData.trade'
    | 'masterData.contacts'
    | 'masterData.branches'
    | 'masterData.projectType'
    | 'masterData.agreements'
    | 'masterData.others'
    | 'masterData.textSnippets'
    | 'masterData.insurances'
    | 'masterData.function'
    | 'staff.permissions'
    | 'staff.employees'
    | 'staff.absences'
    | 'additional.editAllCorrespondence'
    | 'additional.editAllTasks'
    | 'additional.reopenTask'
    | 'additional.downloadProjects'
    | 'additional.downloadContacts'
    | 'additional.downloadInvoice'
    | 'additional.createInvoice'
    | 'additional.releaseInvoice'
    | 'additional.releaseOffer';

export function useViewer() {
    const { viewer } = useSecurity();

    const { data, loading } = useQuery(GetEmployeeDocument, {
        variables: { id: viewer?.app?.mitarbeiterId },
        skip: !Boolean(viewer?.app?.mitarbeiterId),
        context: { clientName: 'ucpw' },
    });

    const employee: any | null = data?.item?.item || null;

    const permissions: Record<string, string[]> = (viewer as Viewer)?.permissions.reduce(
        (acc, permission) => ({ ...acc, [permission.id]: permission.permissions }),
        {}
    );

    const getPermission = (resource: string) => permissions[resource];

    const hasPermission = <R extends Resource, P extends Permission>(
        resource: R,
        permission: P
    ) => {
        if (!resource) {
            return false;
        }
        const resourcePermissions = getPermission(resource);
        return resourcePermissions?.includes(permission);
    };

    const isReadOnly = <R extends Resource>(resource: R) => {
        if (!resource) {
            return false;
        }

        const resourcePermissions = getPermission(resource);
        // to mony permissions
        if (resourcePermissions?.length > 1) {
            return false;
        }

        return resourcePermissions?.includes('view');
    };

    return {
        ...(viewer as Viewer),
        getPermission,
        hasPermission,
        isReadOnly,
        ...(employee && { employee, role: employee?.rolle, roleId: employee?.rolleId }),
        loading,
    };
}
