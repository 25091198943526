import React from 'react';
import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import {
    DocumentDetailsDocument,
    ListElectricityMeterDocument,
    ListOffersDocument,
    ListOffersPositionsDocument,
    ListInvoicesDocument,
    ListInvoicePositionsDocument,
    ListMeasuresDocument,
    SelectSubprojectResidentialUnitsDocument,
    ListOperationReportsDocument,
    ListCalculationPositionsDocument,
    ListCorrespondencesAndEventsDocument,
    ListDeviceUsageDocument,
    GetEmployeeDocument,
    SortOrder,
} from '~/gql/ucpw/graphql';
import { ProjectIds } from '~/pages/projects/types';
import { useViewer, usePdfs } from '~/hooks';
import { client } from '~/apollo';
import log from '~/log';
import { join } from '~/utils';

export const usePdf = ({ subprojectId, projectId }: ProjectIds, callback?: () => void) => {
    const { open } = usePdfs(callback);
    const viewer = useViewer();
    const mitarbeiterIdSachbearbeiter = viewer?.app?.mitarbeiterId;
    const context = { clientName: 'ucpw' };

    const { data: editorData, loading: getEmployeeLoading } = useQuery(GetEmployeeDocument, {
        skip: !Boolean(mitarbeiterIdSachbearbeiter),
        variables: { id: mitarbeiterIdSachbearbeiter as number },
        context,
    });

    const editor = editorData?.item?.item;

    /**
     * Offers, invoices, operationalReports
     * These listings show only entities which haven't been soft deleted !
     */

    const { data: correspondenceAndEventsData, loading: correspondenceAndEventsLoading } = useQuery(
        ListCorrespondencesAndEventsDocument,
        {
            variables: { subprojektId: subprojectId as number },
            skip: !Boolean(subprojectId),
            context,
        }
    );
    const correspondencesAndEvents = correspondenceAndEventsData?.app?.items?.items || [];

    const variables = { filter: { subprojektId: subprojectId }, limit: 50 };
    const { data: offerData, loading: offersLoading } = useQuery(ListOffersDocument, {
        variables,
        context,
    });
    const offers = offerData?.items?.items || [];

    const { data: invoiceData, loading: invoicesLoading } = useQuery(ListInvoicesDocument, {
        variables,
        context,
    });
    const invoices = invoiceData?.items?.items || [];

    const { data: operationalReportData, loading: operationalReportLoading } = useQuery(
        ListOperationReportsDocument,
        {
            variables,
            context,
        }
    );
    const reports = operationalReportData?.items?.items || [];

    const { data: documentDetailsData, loading: documentDetailsLoading } = useQuery(
        DocumentDetailsDocument,
        {
            skip: !Boolean(subprojectId),
            variables: { subprojektId: subprojectId as number },
            context,
        }
    );

    const subproject = documentDetailsData?.item?.item || {};

    const { data: electricityMeterData, loading: electricityMeterLoading } = useQuery(
        ListElectricityMeterDocument,
        {
            variables: {
                limit: 250,
                filter: {
                    subprojektWohneinheit: {
                        subprojektId: subprojectId,
                        subprojekt: {
                            projektId: projectId,
                        },
                    },
                },
            },
            context,
        }
    );
    const meterReadings = electricityMeterData?.items?.items || [];

    const { data: deviceUsageData, loading: deviceUsageLoading } = useQuery(
        ListDeviceUsageDocument,
        {
            variables: {
                limit: 250,
                filter: {
                    subprojektWohneinheit: {
                        subprojektId: subprojectId,
                        subprojekt: {
                            projektId: projectId,
                        },
                    },
                },
            },
            context,
        }
    );
    const deviceUsage = deviceUsageData?.items?.items || [];

    const { data: calculationData, loading: calculationLoading } = useQuery(
        ListCalculationPositionsDocument,
        {
            variables: {
                filter: { subprojektId: subprojectId },
                limit: 1000,
                orderBy: [{ lfdNr: SortOrder.Asc }],
            },
            context,
        }
    );
    const calculationPositions = calculationData?.items?.items || [];

    log.trace('usePdf', { subproject });

    const coverSheet = React.useCallback(async () => {
        await open({
            template: 'coverSheet',
            filename: 'deckblatt.pdf',
            data: { editor, ...subproject, correspondencesAndEvents },
        });
    }, [subproject, subprojectId, correspondencesAndEvents]);

    const allowance = React.useCallback(async () => {
        /** cover limit of 50 "Wohneinheiten" */
        const { data } = await client.query({
            context,
            query: SelectSubprojectResidentialUnitsDocument,
            fetchPolicy: 'network-only',
            variables: {
                limit: 50,
                orderBy: [
                    { wohneinheit: { istGesamtobjekt: SortOrder.Desc } },
                    { wohneinheit: { bezeichnung: SortOrder.Asc } },
                ],
                filter: { subprojektId: subprojectId, wohneinheit: { deletedExists: false } },
            },
        });

        const units = data?.items?.items || [];

        /** cover limit of 250 "Aufmass" for each "Wohneinheit" */
        const subprojektWohneinheit = await Promise.all(
            units
                .filter((unit: any) => !unit?.wohneinheit?.deleted)
                .map(async (unit: any) => {
                    const { wohneinheit = {} } = unit || {};
                    const { data } = await client.query({
                        query: ListMeasuresDocument,
                        variables: {
                            limit: 250,
                            filter: { subprojektWohneinheitId: unit.id },
                        },
                        context,
                    });
                    const aufmass = data?.items?.items || [];
                    return {
                        ...unit,
                        wohneinheit: {
                            ...wohneinheit,
                            bezeichnung:
                                wohneinheit?.bezeichnung === 'Gesamtobjekt'
                                    ? join([
                                          join(
                                              [
                                                  wohneinheit?.projekt?.plz,
                                                  wohneinheit?.projekt?.ort,
                                              ],
                                              ' '
                                          ),
                                          wohneinheit?.projekt?.strasse,
                                      ])
                                    : wohneinheit?.bezeichnung,
                        },
                        aufmass,
                    };
                })
        );

        console.log('allowance', { units, subprojektWohneinheit });

        await open({
            template: 'allowance',
            filename: 'aufmass.pdf',
            data: { ...subproject, subprojektWohneinheit },
        });
    }, [subproject, subprojectId]);

    const leakDetectionReport = React.useCallback(
        async (overrides = {}) => {
            await open({
                template: 'report',
                filename: 'ortungsbericht.pdf',
                data: { ...subproject, einsatzbericht: reports, editor, ...overrides },
            });
        },
        [subproject, editor, subprojectId, reports]
    );

    const getSortByLfdNr = (data: any[]) =>
        sortBy(
            data?.map(({ lfdNr, einzelpreis = 0, ...pos }: any) => ({
                ...pos,
                lfdNr: isNaN(lfdNr) ? lfdNr : parseFloat(lfdNr),
                einzelpreis,
            })),
            'lfdNr'
        );

    const offer = React.useCallback(
        async (overrides = {}) => {
            console.log('usePdf.offer', overrides);
            const isValid = offers.find(
                ({ statusSnippet }: any) => statusSnippet?.name === 'gültig'
            );
            const defaultValues = {
                ...(isValid
                    ? isValid
                    : {
                          ...offers?.[0],
                          angebotspositionen: getSortByLfdNr(calculationPositions || []),
                      }),
            };

            const data: any = {
                editor,
                ...subproject,
                angebote: offers,
                ...defaultValues,
                ...overrides,
            };

            const { data: offersPositionsData } = await client.query({
                context: { clientName: 'ucpw' },
                query: ListOffersPositionsDocument,
                variables: {
                    limit: 1000,
                    filter: { angebotId: data?.id },
                    orderBy: [{ lfdNr: SortOrder.Asc }],
                },
            });

            const offerpositions = offersPositionsData?.items?.items || [];

            await open({
                template: 'offer',
                filename: 'angebot.pdf',
                data: {
                    ...data,
                    ...(offerpositions.length && {
                        angebotspositionen: getSortByLfdNr(offerpositions),
                    }),
                },
            });
        },
        [subproject, editor, subprojectId, calculationPositions, offers]
    );

    const coverLetter = React.useCallback(
        async (overrides = {}) => {
            return await open({
                template: 'coverLetter',
                filename: 'anschreiben.pdf',
                data: { editor, ...subproject, ...overrides },
            });
        },
        [subproject, editor, subprojectId]
    );

    const billedMeterReading = React.useMemo(() => {
        return meterReadings?.filter(({ abrechnen }: any) => Boolean(abrechnen));
    }, [meterReadings]);

    const billedDeviceUsage = React.useMemo(() => {
        return deviceUsage?.filter(({ abrechnen }: any) => Boolean(abrechnen));
    }, [deviceUsage]);

    const invoice = React.useCallback(
        async (overrides = {}) => {
            const isValid = invoices.find(
                ({ statusSnippet }: any) => statusSnippet?.name === 'gültig'
            );

            const defaultValues = {
                ...(isValid ? isValid : invoices?.[0] || {}),
            };

            const data: any = {
                editor,
                stromzaehler: billedMeterReading,
                geraeteeinsatz: billedDeviceUsage,
                ...subproject,
                rechnungen: invoices,
                ...defaultValues,
                ...overrides,
            };

            const { data: invoicePositionsData } = await client.query({
                context: { clientName: 'ucpw' },
                query: ListInvoicePositionsDocument,
                variables: {
                    limit: 1000,
                    filter: { rechnungId: data?.id },
                    orderBy: [{ lfdNr: SortOrder.Asc }],
                },
            });

            const invoicepositions = invoicePositionsData?.items?.items || [];

            await open({
                template: 'invoice',
                filename: 'rechnung.pdf',
                data: {
                    ...data,
                    ...(invoicepositions.length && {
                        rechnungspositionen: getSortByLfdNr(invoicepositions),
                    }),
                },
            });
        },
        [subproject, editor, billedMeterReading, billedDeviceUsage, subprojectId, invoices]
    );

    const assignment = React.useCallback(async () => {
        await open({
            template: 'assignment',
            filename: 'auftragserteilung.pdf',
            data: subproject,
        });
    }, [subproject, subprojectId]);

    const operationalReport = React.useCallback(async () => {
        await open({
            template: 'operationalReport',
            filename: 'einsatzbericht.pdf',
            data: subproject,
        });
    }, [subproject, subprojectId]);

    const measurementLog = React.useCallback(async () => {
        await open({
            template: 'measurementLog',
            filename: 'messprotokoll.pdf',
            data: subproject,
        });
    }, [subproject, subprojectId]);

    const powerConsumptionNotice = React.useCallback(
        async (overrides = {}) => {
            await open({
                template: 'powerConsumptionNotice',
                filename: 'stromverbrauchsmitteilung.pdf',
                data: {
                    editor,
                    ...subproject,
                    stromzaehler: billedMeterReading,
                    ...overrides,
                },
            });
        },
        [subproject, editor, billedMeterReading, subprojectId]
    );

    const devices = React.useCallback(
        async (overrides = {}) => {
            await open({
                template: 'deviceUsage',
                filename: 'geräteeinsatz.pdf',
                data: {
                    editor,
                    ...subproject,
                    geraeteeinsatz: billedDeviceUsage,
                    ...overrides,
                },
            });
        },
        [subproject, editor, billedDeviceUsage, subprojectId]
    );

    const devicesAndPowerConsumptionNotice = React.useCallback(
        async (overrides: any = {}) => {
            const props = {
                editor,
                ...subproject,
            };

            await open({
                template: 'mergePdfs',
                filename: 'geraeteeinsatz.pdf',
                data: {
                    ...(overrides?.powerConsumptionNotice
                        ? {
                              powerConsumptionNotice: {
                                  ...props,
                                  ...overrides?.powerConsumptionNotice,
                              },
                          }
                        : billedMeterReading?.length && !!overrides?.powerConsumptionNotice
                        ? {
                              powerConsumptionNotice: {
                                  ...props,
                                  stromzaehler: billedMeterReading,
                              },
                          }
                        : {}),
                    ...(overrides?.deviceUsage
                        ? {
                              deviceUsage: {
                                  ...props,
                                  ...overrides?.deviceUsage,
                              },
                          }
                        : billedDeviceUsage?.length && !!overrides?.deviceUsage
                        ? {
                              deviceUsage: {
                                  ...props,
                                  geraeteeinsatz: billedDeviceUsage,
                              },
                          }
                        : {}),
                },
            });
        },
        [subproject, editor, billedDeviceUsage, billedMeterReading, subprojectId]
    );

    const loading =
        getEmployeeLoading ||
        documentDetailsLoading ||
        correspondenceAndEventsLoading ||
        electricityMeterLoading ||
        deviceUsageLoading ||
        calculationLoading ||
        offersLoading ||
        invoicesLoading ||
        operationalReportLoading;

    return {
        offers,
        invoices,
        leakDetectionReports: reports,
        allowance,
        assignment,
        coverSheet,
        measurementLog,
        calculationPositions: getSortByLfdNr(calculationPositions),
        offer,
        invoice,
        operationalReport,
        powerConsumptionNotice,
        meterReadings: billedMeterReading,
        devices,
        devicesAndPowerConsumptionNotice,
        deviceUsage: billedDeviceUsage,
        leakDetectionReport,
        subproject,
        coverLetter,
        loading,
    };
};

export const usePdfRefetchQueries = ({ subprojectId }: ProjectIds) => {
    if (!subprojectId) {
        return {};
    }

    const context = { clientName: 'ucpw' };

    const coverSheet = {
        query: ListCorrespondencesAndEventsDocument,
        variables: { subprojektId: subprojectId },
        context,
    };

    const variables = { filter: { subprojektId: subprojectId }, limit: 50 };
    const offers = {
        query: ListOffersDocument,
        variables,
        context,
    };
    const invoices = {
        query: ListInvoicesDocument,
        variables,
        context,
    };
    const reports = {
        query: ListOperationReportsDocument,
        variables,
        context,
    };

    const meterReadings = {
        query: ListElectricityMeterDocument,
        context,
        variables: {
            limit: 250,
            filter: {
                subprojektWohneinheit: {
                    subprojektId: subprojectId,
                },
            },
        },
    };

    const deviceUsage = {
        query: ListDeviceUsageDocument,
        context,
        variables: {
            limit: 250,
            filter: {
                subprojektWohneinheit: {
                    subprojektId: subprojectId,
                },
            },
        },
    };

    const calculationPositions = {
        query: ListCalculationPositionsDocument,
        context,
        variables: {
            limit: 1000,
            filter: { subprojektId: subprojectId },
            orderBy: [{ lfdNr: SortOrder.Asc }],
        },
    };

    const subproject = {
        query: DocumentDetailsDocument,
        context,
        variables: { subprojektId: subprojectId },
    };

    return {
        coverSheet,
        offers,
        invoices,
        reports,
        meterReadings,
        deviceUsage,
        calculationPositions,
        subproject,
        refetchQueries: [
            coverSheet,
            offers,
            invoices,
            reports,
            meterReadings,
            deviceUsage,
            calculationPositions,
            subproject,
        ],
    };
};
