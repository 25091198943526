import { gql, useApolloClient } from '@apollo/client';
import { format } from 'date-fns';
import { omit } from 'lodash';
import { Aufmass, Kontakt, Mitarbeiter, Subprojekt } from '~/gql/ucpw/graphql';
import { CreatePdfResponse } from '~/gql/ucc/graphql';
import { generateProjectNumber, getParticipant, isJsonString } from '~/utils';
import log from '~/log';

const CREATE_PDF = gql`
    mutation CreatePdf($template: String!, $filename: String!, $data: AWSJSON) {
        pdf {
            createPdf(template: $template, filename: $filename, data: $data) {
                signedUrl
                versionId
                error
            }
        }
    }
`;

export function usePdfs(callback?: () => void) {
    const client = useApolloClient();

    async function createPdf(variables: { template: string; filename: string; data: string }) {
        const [filename] = variables?.filename?.split('.');
        const timestamp = format(new Date(), 'yyyyMMdd_HHmmss');
        const fileTimestampName = [filename, timestamp].join('_') + '.pdf';

        const response = await client.mutate({
            mutation: CREATE_PDF,
            variables: { ...variables, filename: fileTimestampName },
        });
        const pdf = response?.data?.pdf?.createPdf || {};

        return pdf;
    }

    async function open({
        template,
        filename,
        data,
    }: {
        template: Templates;
        filename: string;
        data: Record<string, any>;
    }) {
        log.debug('resolveTemplateData', {
            template,
            filename,
            data: resolveTemplateData(template as Templates, data),
        });

        const templateData = resolveTemplateData(template as Templates, data);

        const response: CreatePdfResponse = await createPdf({
            template,
            filename,
            data: JSON.stringify(templateData),
        });

        if (response?.signedUrl) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgent
            const userAgentString = navigator.userAgent;
            const chromeAgent = userAgentString.indexOf('Chrome') > -1;
            // Detect Safari
            let safariAgent = userAgentString.indexOf('Safari') > -1;
            // Discard Safari since it also matches Chrome
            if (chromeAgent && safariAgent) {
                safariAgent = false;
            }

            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('display', 'none');
            a.setAttribute('href', response?.signedUrl);
            a.setAttribute('title', filename);
            if (chromeAgent) {
                a.setAttribute('download', filename);
            }
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noopener
            // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noreferrer
            a.setAttribute('rel', 'noopener noreferrer');
            a.setAttribute('target', safariAgent ? '_top' : '_blank');
            a.click();
            document.body.removeChild(a);
            callback?.();
        }

        return { response, template, data };
    }

    return { createPdf, resolveTemplateData, open };
}

function resolveLocation(data: Record<string, unknown>) {
    return {
        street: data?.strasse,
        location: data?.ort,
        zipCode: data?.plz,
    };
}

const removeEmptyStrings = (data: Record<string, any> = {}) =>
    Object.entries(data).reduce(
        (acc: any, [key, value]: any) => ({ ...acc, [key]: value === ' ' ? '' : value }),
        {}
    );

function resolveContact(contact: Kontakt) {
    return {
        ...removeEmptyStrings({
            company1: contact?.firma1,
            company2: contact?.firma2,
            name: contact?.name,
            surname: contact?.vorname,
            phone: contact?.telefon,
            mobile: contact?.telefonMobil,
            directDialing: contact?.telefonDirekt,
        }),
        ...(contact?.anredeSnippet && {
            salutationSnippet: {
                name: contact?.anredeSnippet?.name,
                short: contact?.anredeSnippet?.kuerzel,
            },
        }),
        ...resolveLocation(contact),
    };
}

function resolveProjectNumber(data: Subprojekt) {
    return generateProjectNumber(
        data?.projekt?.anlageDatum,
        data?.projekt?.niederlassung?.nummer as number,
        data?.projekt?.lfdNr as number,
        data?.lfdNr
    );
}

function resolveBaseData(data: Subprojekt & { editor?: Mitarbeiter }) {
    return {
        ...removeEmptyStrings({
            weg: data?.weg,
            claimNumber: data?.schadenNr,
            insuranceCertificate: data?.scheinNr,
            yourSign: data?.ihrZeichen,
            creationDate: data?.projekt?.anlageDatum,
        }),
        remark: data?.bemerkung?.raw,
        projectNumber: resolveProjectNumber(data),
        ...resolveLocation(data?.projekt || {}),
        insurance: {
            description: data?.versicherung?.bezeichnung,
            ...resolveLocation(data?.projekt || {}),
        },
        ...(data?.editor && {
            editor: {
                name: data?.editor?.name,
                surname: data?.editor?.vorname,
                phone: data?.editor?.telefon,
            },
        }),
    };
}

function getClient(participants: any) {
    return { client: resolveContact(getParticipant(participants, 'Auftraggeber')) };
}

function getDiscount(cutPrice: string = '', totalNet: number = 0) {
    if (!cutPrice || ['/', '-'].includes(cutPrice)) {
        return {};
    }
    const discount = cutPrice.replace(',', '.');

    if (cutPrice.endsWith('%')) {
        const percent = parseFloat(discount.replace('%', ''));
        return { discount: ((percent / 100) * totalNet).toFixed(2) };
    }

    return { discount };
}

function getTotalNet(positions: any[] = []) {
    return positions?.reduce?.(
        (acc: number, pos: Record<string, any>) => acc + pos?.menge * pos?.einzelpreis,
        0
    );
}

const templateResolver = {
    invoice: (data: any) => {
        const totalNet = data?.gesamtpreisNetto || getTotalNet(data?.rechnungspositionen);
        return {
            ...getClient(data?.beteiligte),
            ...resolveBaseData(data),
            vat: data?.mwstSatz,
            number: data?.nummer || data?.id,
            date: data?.datum,
            text: data?.freitext,
            footerText: data?.freitextFooter,
            totalNet,
            invoiceItems: data?.rechnungspositionen,
            electricityMeters: data?.stromzaehler,
            deviceUsage: data?.geraeteeinsatz,
            attachments: data?.dokument || [],
            ...getDiscount(data?.rabatt, totalNet),
        };
    },
    offer: (data: any) => {
        const totalNet = data?.gesamtpreisNetto || getTotalNet(data?.angebotspositionen);
        return {
            ...getClient(data?.beteiligte),
            ...resolveBaseData(data),
            vat: data?.mwstSatz,
            number: data?.nummer || data?.id,
            date: data?.datum,
            text: data?.freitext,
            footerText: data?.freitextFooter,
            totalNet,
            offerItems: data?.angebotspositionen,
            attachments: data?.dokument || [],
            ...getDiscount(data?.rabatt, totalNet),
        };
    },
    powerConsumptionNotice: (data: any) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        return {
            client: data?.client ? resolveContact(data?.client) : resolveContact(client),
            ...(data?.editor && {
                editor: { name: data?.editor?.name, surname: data?.editor?.vorname },
            }),
            electricityMeters: data?.stromzaehler || [],
            ...omit(resolveBaseData(data), 'weg'),
        };
    },
    deviceUsage: (data: any) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        return {
            client: data?.client ? resolveContact(data?.client) : resolveContact(client),
            ...(data?.editor && {
                editor: { name: data?.editor?.name, surname: data?.editor?.vorname },
            }),
            devices: data?.geraeteeinsatz || [],
            ...resolveBaseData(data),
        };
    },
    measurementLog: (data: any) => {
        return { ...resolveBaseData(data) };
    },
    operationalReport: (data: any) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        return {
            client: resolveContact(client),
            ...resolveBaseData(data),
            projectType: data?.projekttyp?.name,
            costCentre: data?.projekt?.niederlassung?.nummer,
        };
    },
    assignment: (data: any) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        return { client: resolveContact(client), ...resolveBaseData(data) };
    },
    coverLetter: (data: any) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        return {
            client: data?.client ? resolveContact(data?.client) : resolveContact(client),
            text: data?.freitext,
            ...omit(resolveBaseData(data), 'weg'),
            ...(data?.editor && {
                editor: { name: data?.editor?.name, surname: data?.editor?.vorname },
            }),
        };
    },
    report: (data: any) => {
        const trades = data?.report?.einsatzberichtDetail
            ?.reduce((acc: any, detail: any) => {
                const bezeichnung = detail?.einsatzberichtOption?.bezeichnung;
                if (['weitere_arbeiten', 'ag_handwerker'].includes(bezeichnung)) {
                    const text = isJsonString(detail?.text)
                        ? JSON.parse(detail?.text)
                        : detail?.text;
                    const isHandwerker = bezeichnung === 'ag_handwerker';
                    const label = isHandwerker
                        ? 'AG hat eigene Handwerker'
                        : detail?.jaNein
                        ? 'Weitere Arbeiten erwünscht (in Klärung)'
                        : 'Weitere Arbeiten nicht erwünscht';
                    const trades =
                        Array.isArray(text) && text?.length
                            ? text.map((item) => item?.label)?.join(', ')
                            : 'keine';
                    if (trades === 'keine' && isHandwerker) {
                        return acc;
                    }

                    return [...acc, { label, trades }];
                }
                return acc;
            }, [])
            ?.sort((a: any, b: any) => (a?.label < b?.label ? 1 : -1));

        const typename = data?.report?.einsatzberichtTyp?.name;

        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        const policyholder = getParticipant(data?.beteiligte, 'Versicherungsnehmer');
        const description = data?.report?.einsatzberichtDetail?.find(
            ({ einsatzberichtOption: { bezeichnung } }: any) =>
                bezeichnung === 'beschreibung_einsatzbericht'
        );

        const attachments = data?.report?.einsatzberichtDetail?.find(
            ({ einsatzberichtOption: { bezeichnung } }: any) => bezeichnung === 'fotodokumentation'
        );

        console.log('tempateResolver.report', {
            client: resolveContact(client),
            trades,
            policyholder: resolveContact(policyholder),
            text: description?.text,
            reportDate: data?.report?.datum,
            reportDetails: data?.report?.einsatzberichtDetail,
            report: data?.report,
            reportType: typename,
            ...resolveBaseData(data),
            ...(attachments && attachments?.text && isJsonString(attachments?.text)
                ? { attachments: JSON.parse(attachments?.text) }
                : { attachments: [] }),
        });

        return {
            client: resolveContact(client),
            policyholder: resolveContact(policyholder),
            text: description?.text,
            trades,
            reportDate: data?.report?.datum,
            reportDetails: data?.report?.einsatzberichtDetail,
            report: data?.report,
            reportType: typename,
            anfahrt: [data?.report?.anfahrtFrom || '', data?.report?.anfahrtUntil || ''],
            einsatz: [data?.report?.einsatzFrom || '', data?.report?.einsatzUntil || ''],
            abfahrt: [data?.report?.abfahrtFrom || '', data?.report?.abfahrtUntil || ''],
            ...resolveBaseData(data),
            ...(attachments &&
                attachments?.text &&
                isJsonString(attachments?.text) && { attachments: JSON.parse(attachments?.text) }),
        };
    },
    allowance: (data: any) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        const residentialUnits = data?.subprojektWohneinheit?.map((unit: any) => ({
            description: unit?.wohneinheit?.bezeichnung,
            allowance: unit?.aufmass?.map((aufm: Aufmass) => ({
                description: aufm.bezeichnung,
                type: aufm?.typSnippet?.name,
                l: aufm?.laenge,
                b: aufm?.breite,
                h: aufm?.hoehe,
            })),
        }));
        return {
            client: resolveContact(client),
            ...resolveLocation(data?.projekt),
            residentialUnits,
            ...resolveBaseData(data),
        };
    },
    coverSheet: (data: Subprojekt & { editor: Mitarbeiter; correspondencesAndEvents: any[] }) => {
        const client = getParticipant(data?.beteiligte, 'Auftraggeber');
        return {
            client: resolveContact(client),
            events: data?.termine || [],
            correspondences: data?.korrespondenzen || [],
            correspondencesAndEvents: data?.correspondencesAndEvents || [],
            tasks: data?.aufgaben || [],
            participants: data?.beteiligte,
            ...(data?.editor && {
                editor: { name: data?.editor?.name, surname: data?.editor?.vorname },
            }),
            ...resolveBaseData(data),
        };
    },
    mergePdfs: (data: any) => data,
};

type Templates = keyof typeof templateResolver;

export function resolveTemplateData(template: Templates, data: any) {
    const resolver = templateResolver?.[template];

    if (template === 'mergePdfs') {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const template = key as keyof typeof templateResolver;
            return {
                ...acc,
                [template]: {
                    ...(templateResolver?.[template] &&
                        templateResolver?.[template]?.(value as any)),
                },
            };
        }, {});
    }

    if (resolver) {
        return resolver?.(data);
    }

    throw new Error(`${template} does not exist!`);
}
